import { toast, ToastContainer } from 'react-toastify';
import {
    Container,
    AuthFormCard,
    Label,
    Input,
    Box,
    BoxContent,
    Button,
    SubTitle,
} from './styled';
import Logo from '../../assets/logo.svg'
import { useForm } from '../../hooks/useForm';
import { MouseEventHandler, useEffect, useState } from 'react';
import { Loading } from '../../components/Loading';
import ErrorImage from '../../assets/bx_error-circle.svg';
import { api } from '../../services/requests';
import { useUrlParam } from '../../hooks/useUrlParam';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface IForm {
    password: string;
    confirmPassword: string;
}

interface IisLoading {
    token: boolean;
    password: boolean;
}

export const ResetPassword = () => {
    const { t, i18n } = useTranslation();

    document.title = i18n.language === 'pt' ? "Digite sua nova senha" : "Enter your new password";

    const { form, onChange } = useForm<IForm>({ password: "", confirmPassword: "" });
    const [errors, setErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState<IisLoading>({ token: true, password: false });
    const [status, setStatus] = useState("");
    const params = useUrlParam();
    const navigate = useNavigate();

    const token = params.getParam("token");

    useEffect(() => {
        if (!token) {
            setStatus("error");
            return;
        }

        api.post("/checkSession", {}, {
            headers: {
                authorization: token
            }
        }).then(() => {
            setIsLoading(prevLoading => ({ ...prevLoading, token: false }));
            setStatus("success");
        }).catch(err => {
            if (err.response.status === 401) {
                setStatus("error");
                setIsLoading(prevLoading => ({ ...prevLoading, token: false }));
                return;
            }
        });
    }, [token]);

    const handleResetPassword: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();

        if (form.password.length < 4) {
            setErrors({ ...errors, password: "Senha deve ter no mínimo 4 caracteres" });
            return;
        }

        if (form.password !== form.confirmPassword) {
            setErrors({ ...errors, confirmPassword: "Senhas não conferem" });
            return;
        }

        setIsLoading({ ...isLoading, password: true });

        const body = {
            new_password: form.password,
            token: token
        }

        api.post("/resetPassword/token", body).then(() => {
            setIsLoading({ ...isLoading, password: false });
            navigate("/login");
        }).catch(() => {
            setIsLoading({ ...isLoading, password: false });
            toast.error("Erro ao resetar senha");
        });
    }

    if (status === "error") {
        return <ErrorContent t={t} />
    } else if (isLoading.token) {
        return <LoadingContent t={t}/>
    } else {
        return (
            <Container style={{ height: "100vh" }}>
                <ToastContainer />

                <AuthFormCard>

                    <img src={Logo} alt="logo da empresa" />

                    <SubTitle>
                        {t('resetPasswordTitle')}
                    </SubTitle>

                    <Box columns={1}>
                        <BoxContent>
                            <Label htmlFor='password'>
                                {t('resetPasswordLabel')}
                            </Label>

                            <Input
                                id='password'
                                placeholder={t('resetPasswordInput') || ''}
                                type={"password"}
                                value={form.password}
                                onChange={e => onChange(e.target.value, "password")}
                                onBlur={e => {
                                    const value = e.target.value;

                                    if (value.length < 4) {
                                        setErrors({ ...errors, password: "Senha deve ter no mínimo 4 caracteres" });
                                    } else if (errors.password) {
                                        setErrors({ ...errors, password: "" });
                                    }
                                }}
                                error={errors.password}
                            />
                        </BoxContent>
                    </Box>

                    <Box columns={1}>
                        <BoxContent>
                            <Label htmlFor='confirmPassword'>
                                {t('confirmPasswordLabel')}
                            </Label>

                            <Input
                                id="confirmPassword"
                                placeholder={t('confirmPasswordInput') || ''}
                                value={form.confirmPassword}
                                type={"password"}
                                onChange={e => onChange(e.target.value, "confirmPassword")}
                                onBlur={e => {
                                    const value = e.target.value;

                                    if (value !== form.password) {
                                        setErrors({ ...errors, confirmPassword: "Senhas não conferem" });
                                    } else if (errors.confirmPassword) {
                                        setErrors({ ...errors, confirmPassword: "" });
                                    }
                                }}
                                error={errors.confirmPassword}
                            />
                        </BoxContent>
                    </Box>

                    <Button
                        onClick={handleResetPassword}
                        disabled={isLoading.password}
                    >
                        {
                            isLoading.password ? (
                                <div style={{ height: "35px", width: "35px" }}><Loading /></div>
                            ) : (
                                t('resetPasswordButton')
                            )
                        }
                    </Button>
                </AuthFormCard>
            </Container>
        )
    }
}

interface IGenericTranslationProps {
    t: TFunction
}

const LoadingContent = (props: IGenericTranslationProps) => {
    const { t } = props;

    return (
        <Container style={{ height: "100vh" }}>
            <ToastContainer />

            <AuthFormCard style={{ width: "400px" }}>
                <div>
                    <div style={{ height: "80px", width: "80px", margin: "0 auto" }}><Loading /></div>
                    <SubTitle>
                        {t('resetPasswordLoading')}
                    </SubTitle>
                </div>
            </AuthFormCard>
        </Container>
    )
}

const ErrorContent = (props: IGenericTranslationProps) => {
    const { t } = props;

    return (
        <Container style={{ height: "100vh" }}>
            <ToastContainer />

            <AuthFormCard>
                <div>
                    <img src={ErrorImage} alt="Erro" style={{ height: "80px", width: "80px", margin: "0 auto" }} />
                    <SubTitle>
                        {t('resetPasswordError')}
                    </SubTitle>
                </div>
            </AuthFormCard>
        </Container>
    )
}