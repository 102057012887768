import axios from "axios";

const BaseUrl = "https://safra-api.herokuapp.com/v1/"

// const BaseUrl = "http://localhost:8080/v1";

export const api = axios.create(
    {
        baseURL: BaseUrl
    }
)
