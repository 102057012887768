import PlatformImage from "../../assets/platform.webp";
import APIImage from "../../assets/api.webp";
import Monitoramento from '../../assets/DASH_FINAL.webp';
import Cadastro from '../../assets/CADASTRO_FINAL2.webp';
import CapturaDeDados from '../../assets/NDVI_FINAL1.webp';
import Analise from '../../assets/SOLO_FINAL.webp';
import AgroJr from '../../assets/agrojr-logo.webp';
import Neosolo from '../../assets/neosolo-logo.webp';
import SentinelHub from '../../assets/sentinel_hub-logo.webp';

export const howWorksInfo = [
    {
        title: "Cadastro",
        image: Cadastro,
        functionalities: [
            "Escolha o plano que mais se encaixa para você",
            "Cadastre-se no nosso site",
            "Delimite seu talhão através do nosso mapa interativo",
            "Registre as culturas, datas de plantio e colheita de suas safras",
            "Pronto! Você já pode começar a usar o nosso serviço"
        ]
    },
    {
        title: "Captura de dados",
        image: CapturaDeDados,
        functionalities: [
            "O nosso aplicativo integra os melhores satélites do mercado, use para registrar dados da sua lavoura",
            "Importe os resultados das amostras do solo georreferenciadas com maior facilidade",
            "Os dados serão analisados pelo nosso servidor",
            "Receba mapas de NDVI para acompanhar o desenvolvimento e umidade do solo da sua lavoura",
            "Receba imagens da sua lavoura em tempo real"
        ]
    },
    {
        title: "Monitoramento",
        image: Monitoramento,
        functionalities: [
            "Monitore as condições do seu plantio em tempo real",
            "Monitore as mudanças no clima em cada talhão",
            "Verifique de modo detalhado a quantidade de nuvens e eventos climáticos em tempo real",
            "Visualize histórico da temperatura, precipitação e pluviometria por talhão"
        ]
    },
    {
        title: "Análise",
        image: Analise,
        functionalities: [
            "Analise o desenvolvimento da sua lavoura, através dos dados obtidos pelo nosso sistema e compare-as com datas anteriores",
            "Obtenha mapas para um diagnóstico preciso da sua lavoura, a partir das análises de solo georreferenciadas",
            "Consulte os mapas de fertilidade e de aplicação por taxa variável para um diagnóstico preciso da sua lavoura",
            "Receba gráficos, visualize os resultados com maior praticidade e compare-os com safras anteriores",
            "Determine as soluções de maneira assertiva"
        ]
    }
]

export const servicesInfo = [
    {
        title: "Plataforma Biosat",
        photo: PlatformImage,
        infos: [
            "Permite o monitoramento do cultivo em tempo real",
            "Através de análises de solo georreferenciadas, obtenha mapas de aplicação por taxa variável",
            "Utilize mapas para uma aplicação de insumos com maior precisão",
            "Reduza os custos de aquisição e aplicação dos insumos",
            "Obtenha maior aproveitamento e eficiência no uso de nutrientes",
            "Utilize as informações para solucionar desafios"
        ],
        redirectTo: "/pricing/platform"
    }, {
        title: "Safra API",
        photo: APIImage,
        infos: [
            "Histórico de índices vegetativos e condições climáticas",
            "Previsão climática de 15 dias por ponto",
            "Dados georreferenciados processados especialmente para uso agropecuário",
            "Ferramentas de geoprocessamento que possibilitam o calculo da área, distância e transformação de coordenadas com praticidade",
            "Fácil integração com qualquer sistema",
            "Suporte técnico disponível"
        ],
        redirectTo: "/pricing/safra"
    }
]

export const PartnersInfo = [
    {
        name: "AgroJr",
        image: AgroJr
    }, {
        name: "Neosolo",
        image: Neosolo
    }, {
        name: "Sentinel Hub",
        image: SentinelHub
    }
]