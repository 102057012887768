import styled, { keyframes } from 'styled-components';

const emerge = keyframes`
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const Container = styled.div`
    display: grid;
    place-content: center;
    place-items: center;
    width: 100%;
    min-height: 100vh;
    background: url("/backgroundAuth.webp") no-repeat center center fixed;
    position: relative;
    font-size: 14px;
    
    :after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #003F51;
        opacity: .8;
    }

    @media (max-width: 800px) {
        height: 100%;
        width: 100%;
    }
`;

export const AuthFormCard = styled.div`
  z-index: 1;
  background: #FFFFFFE5;
  max-width: 400px;
  padding: 1rem 2rem;
  margin: 2rem 0;
  border-radius: 16px;
  max-height: 100%;

  @media (max-width: 800px) {
    padding: 1rem;
    max-width: 90% !important;
  }

  img {
    width: 250px;
    margin: 0 auto;
    display: block;

    @media (max-width: 499px) {
      width: 100%;
    }
  }
`;

export const Input = styled.input<{ error?: boolean }>`
    background: #F7F8FC;
    border: 1px solid ${props => props.error ? '#FF0000' : 'rgba(55, 73, 87, 0.1)'};
    width: calc(100% - .5rem);
    height: 45px;
    border-radius: 8px;
    padding-left: .5rem;
    font-family: Inter,serif;
    transition: all .2s ease-in-out;
    
    :focus {
    outline: none;
    }
`;

export const Label = styled.label`
    display: inline-block;
    font-family: Inter, serif;
    margin-top: .5rem;
    margin-bottom: .5rem;

    & > span {
        // vermelho cor de erro
        color: #FF0000;
        margin-left: 2rem;
        animation: ${emerge} .2s ease-in-out;
    }
`;

export const Link = styled.a`
    display: inline-block;
    color: #62C77A;
    text-decoration: underline;
    font-family: Inter, serif;
    font-size: .8rem;
    margin-top: 15px;
    cursor: pointer;
`;

export const Button = styled.button`
    width: 100%;
    height: 40px;
    padding: 10px;
    line-height: 1rem;
    font-size: 1rem;
    background: #62C77A;
    border: none;
    border-radius: 8px;
    margin-top: .5rem;
    text-align: center;
    font-family: Inter, serif;
    font-weight: bold;
    color: #FFF;
    cursor: pointer;
    transition: all .2s ease-in-out;
    
    :hover {
        background: #4FAE6A;
    }

    &:disabled {
      cursor: not-allowed;
      display: grid;
      place-items: center;
      place-content: center;
      background: #4FAE6A;
  }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: .5rem;
    flex-direction: column;
`;

export const Text = styled.p`
    font-family: Inter, serif;
    font-size: .8rem;
    margin-top: 15px;
    text-align: center;
    line-height: 1rem;
`;

export const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content:center; */
    width: 75vw;
    height: 100vh;
`

export const SubTitle = styled.h2`
    font-family: Inter, serif;
    font-size: 1rem;
    font-weight: 600;
    color: #464F60;
    margin-top: 1rem;
    width: 100%;
    text-align: center;
`;

export const Title = styled.h1`
    font-family: Inter, serif;
    font-size: 32px;
    font-weight: 400;
    color: #464F60;
    margin: 1rem 0;

    @media (max-width: 800px) {
        font-size: 16px;
        width: 100%;
        text-align: center;
        line-height: 20px;
        font-weight: 600;
    }
`;

export const Box = styled.div<{ columns: number }>`
    display: grid;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    grid-gap: 1rem;
    margin: 1rem 0;
    width: 100%;

    @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
    }
`;

export const BoxContent = styled.div`
    width: 100%;
`;

export const CheckBox = styled.input`
    margin-right: .5rem;
`;
