export const validatePhone = (phoneNumber: string) => {
    const onlyDigits = phoneNumber.replace(/\D/g, '');

    if (onlyDigits.length !== 11) {
        return false;
    }

    if (onlyDigits[2] !== '9') {
        return false;
    }

    const ddd = Number(onlyDigits.substring(1, 3));
    if (ddd < 11 || ddd > 99) {
        return false;
    }

    return true;
}