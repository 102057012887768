import { useNavigate } from "react-router-dom";
import SuccessImage from '../../assets/akar-icons_circle-check.svg';
import { Form } from "../../components/PaymentForm/styled";
import { useTranslation } from "react-i18next";

export const PaymentSuccess = () => {

    const navigate = useNavigate()
    const { t } = useTranslation();

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh"
        }}>
            <Form>
            <Form.Card>
                <Form.Result>
                    <Form.ResultTitle>
                        {t('successPaymentTitle')}
                    </Form.ResultTitle>

                    <Form.ResultImageContainer>
                        <img src={SuccessImage} alt="Sucesso" />
                    </Form.ResultImageContainer>

                    <Form.ResultText>
                        {t('successPaymentMessage')}
                    </Form.ResultText>
                </Form.Result>

                <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "2rem 0" }}>
                    <Form.Button
                        onClick={() => navigate("/dashboard?tab=plans")}
                    >
                        {t('successPaymentButton')}
                    </Form.Button>
                </div>
            </Form.Card>
        </Form>
        </div>
    )
}