import { SliderContainer } from "./styled"

interface SliderContainerProps {
    children: React.ReactNode
}

export const Container = (props: SliderContainerProps) => {
    const { children } = props
    
    return (
        <SliderContainer>
            {children}
        </SliderContainer>
    )
}