import styled from "styled-components";

const Container = styled.div`
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
`;

const Content = styled.div<{ items: number }>`
    width: ${({ items }) => `${items * 100}%`};
    height: 100%;
    display: grid;
    grid-template-columns: ${({ items }) => `repeat(${items}, 1fr)`};
    grid-template-rows: 1fr;
    grid-gap: 0;
    transition: 1s;
`;

export const SliderContainer = Object.assign(Container, { Content });