import { PlanContainer } from './styled';
import { useTranslation } from 'react-i18next';
interface IPlan {
    plans: {
        title: string,
        benefits: string[],
        price: number,
    }[],
    setShowCheckout?: (value: boolean) => void,
    setSelectedPlan: (value: number) => void,
    selectedPlan: number,
    type: "PLATFORM" | "API",
}

export const PlanCard = (props: IPlan) => {

    const { plans, setSelectedPlan, selectedPlan, type } = props;

    const { t } = useTranslation();

    return (
        <PlanContainer>
            {
                plans.map((plan, index) => (
                    <PlanContainer.CardContainer>
                        <PlanContainer.Card>
                            <PlanContainer.Plan key={index}>
                                <h3>{`${plan.title}`}</h3>

                                <div style={{ margin: "2rem 0" }}>
                                    {type === 'PLATFORM' && t('platformPlanPrice')}
                                    <span>
                                        {` ${plan.price.toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })} ${t('platformPlanPrice2')}`}
                                    </span>
                                </div>
                                <h4>{t('benefitsTitle')}: </h4>

                                <ul>
                                    {
                                        plan.benefits.map((benefit, index) => (
                                            <li key={index}>{benefit}</li>
                                        ))
                                    }
                                </ul>
                            </PlanContainer.Plan>

                        </PlanContainer.Card>

                        <PlanContainer.Button
                            disabled={selectedPlan === index ? true : false}
                            onClick={() => {
                                setSelectedPlan(index);
                            }}
                        >
                            {
                                selectedPlan === index ? t('planButton2') : t('planButton')
                            }
                        </PlanContainer.Button>
                    </PlanContainer.CardContainer>
                ))
            }
        </PlanContainer>
    )
}