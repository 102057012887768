import { Route, Routes, BrowserRouter } from 'react-router-dom'
import Login from '../pages/auth/login'
import Recover from '../pages/auth/recover'
import { Payment } from '../pages/Payment'
import Signup from '../pages/auth/signup'
import {HomePage} from "../pages/Home";
import { Platform } from "../pages/Pricing/platform";
import { Safra } from "../pages/Pricing/safra";
import { Dashboard } from '../pages/Dashboard'
import ProtectedRoute from './protectedRoute'
import { ResetPassword } from '../pages/auth/resetPassword'
import { LogoutPage } from '../pages/LogoutPage'
import { Error } from '../pages/Error'
import { Blog } from '../pages/Blog'
import { PaymentSuccess } from '../pages/PaymentSuccess'

const Router = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path='/login' element={<Login />} />
                <Route path='/recover' element={<Recover />} />
                <Route path='/signup' element={<Signup />} />
                <Route path='/payment' element={<Payment />} />
                <Route path="/pricing/platform" element={<Platform />} />
                <Route path="/pricing/safra" element={<Safra />} />
                <Route path='/dashboard' element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                } />
                <Route path="/reset-password" element={<ResetPassword />} />
                {/* <Route path='/payment/debug' element={<DebugPayment />} /> */}
                <Route path='/logout' element={<LogoutPage />} />
                <Route path='/error' element={<Error />} />
                <Route path='/blog' element={<Blog />} />
                <Route path='/blog/:postLink' element={<Blog />} />
                <Route path='/payment/success' element={<PaymentSuccess />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router
