export function getPostalCodeMask(input: string) {
    input = input.replace(/[\(\)\-\.\s\/\\]/g, '');
    let postalCode = "";

    if (input.length === 0) {
        return "";
    }

    if (input.length < 8) {
        for (let i = 0; i < input.length; i++) {
            if (i === 5) {
                postalCode += "-";
            }
            postalCode += input[i];
        }
    } else {
        postalCode = input.substring(0, 5) + "-" + input.substring(5, 8);
    }
    
    return postalCode;
};