import { CheckoutPrice as CheckoutContainer } from './styled'

interface CheckoutPriceProps {
    title: string;
    text: string | number;
    quantity?: number;
    isTotal?: boolean;
    color?: string;
}

export const CheckoutPrice = (props: CheckoutPriceProps) => {
    return (
        <CheckoutContainer>
            <CheckoutContainer.Group style={{ marginTop: props.isTotal ? "2rem" : 0 }}>
                <CheckoutContainer.Title>
                    {props.title}
                </CheckoutContainer.Title>

                <CheckoutContainer.Title isTotal={props.isTotal} color={props.color}>
                    {props.text}
                </CheckoutContainer.Title>
            </CheckoutContainer.Group>

            {
                props.quantity && (
                    <CheckoutContainer.Group>
                        <span>{`Qtd: ${props.quantity}`}</span>
                    </CheckoutContainer.Group>
                )
            }
        </CheckoutContainer>
    )
}