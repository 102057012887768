import { useEffect, useRef } from "react"
import { SliderContainer } from "./styled"

interface SliderItemProps {
    children: React.ReactNode
    items: number,
    currentSlide: number
}

export const SliderItem = (props: SliderItemProps) => {
    const { children, items, currentSlide } = props

    const contentRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const translateX = (currentSlide -1) * 100

        if (contentRef.current) {
            contentRef.current.style.transform = `translate3d(-${translateX}vw, 0, 0)`
        }

    }, [currentSlide])

    return (
        <SliderContainer.Content
            items={items}
            ref={contentRef}
        >
            {children}
        </SliderContainer.Content>
    )
}