export function validateCreditCard(input: string) {
  var input = input.replace(/\D/g, '');
  var sum = 0;
  var shouldDouble = false;
  
  for (var i = input.length - 1; i >= 0; i--) {
    var digit = parseInt(input.charAt(i));

    if (shouldDouble) {
      if ((digit *= 2) > 9) digit -= 9;
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }
  return (sum % 10) == 0;
}