import styled from 'styled-components';

const Main = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  grid-gap: 1rem;
  color: #FFF;

  @media (max-width: 1300px) {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-flow: row;
  }

  @media (max-width: 870px) {
      grid-template-columns: repeat(1, 1fr);
      grid-auto-flow: row;
  }

  & > svg {
    padding: 0 1rem;
    cursor: pointer;
    font-size: 2rem;
    transition: .2s;
    user-select: none;
    
    &:hover {
        transform: scale(1.5);
    }

    @media (max-width: 499px) {
      &:hover {
        transform: scale(1);
      }
    }
  }
`;

const Card = styled.div`
    background: #61C67A33;
    width: 400px;
	  margin-top: 2.5rem;
    color: #FFF;
    border-radius: 12px;
    overflow-x: hidden;
    position: relative;

    @media (max-width: 500px) {
      width: 100%;
    }

    @media (max-width: 400px) {
      width: 100%;
    }
`;

const Wrapper = styled.div`
  height: 100%;
  width: 300%;
  display: flex;
  flex-direction: row;
  transition: .5s;
`;

const Plan = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 400px !important;
  padding: 12px 20px 24px;
  overflow-y: scroll;
  transition: all .5s;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #61C67A33;
  }

  &::-webkit-scrollbar-thumb {
    background: #61C67A;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #61C67A;
  }

  & > h3 {
    font-family: Poppins, serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: .5rem;
    width: 100%;
  }

  & > h4 {
    font-family: Poppins, serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
  }

  & > ul {
    margin-top: 1rem;
    width: 100%;
    list-style: outside;
    padding: 0 .5rem;
  }

  & > div {
    width: 100%;
    font-family: Archivo, serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    
  }

  li {
    font-family: Poppins, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    width: 100%;
    margin: .2rem 0;
  }
`;

const Badge = styled.div`
  width: auto;
  height: auto;
  background: #f27e30eb;
  border-radius: 12px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #FFF;
  font-family: Poppins, serif;
  font-weight: 600;
  font-size: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  padding: .2rem .5rem;
  line-height: 1.2rem;
  text-align: left;

  & > span {
    font-size: .9rem;
  }
`;

const Button = styled.button`
  opacity: .8;
  background: #D66B30;
  width: 100%;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: .2s;
  bottom: 0;
  color: #fff;
  font-family: Poppins, serif;
  font-weight: 600;
  font-size: 1rem;
  padding: .4rem;

  :disabled {
    background: #F27E30;
    opacity: 1;

    cursor: not-allowed;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 25px 0;
`;


export const PlanContainer = Object.assign(Main, {
  Card,
  Wrapper,
  Plan,
  Badge,
  Button,
  CardContainer
});

