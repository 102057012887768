import styled from "styled-components";

const Container = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin: 0 auto;
    width: 100%;
    height: 10vh;
    background: #07130A;

    & > a {
        height: 100%;
    }

    & img {
        height: 100%;
        object-fit: contain;
    }
`;

export const BlogNav = Object.assign(Container, {});