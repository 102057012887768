import {
    Nav,
    Logo,
    Menu,
    MenuItem,
    MenuLink,
    Language,
    LanguageContainer,
} from './styled';
import LogoImg from '../../assets/logo_biosat2.svg';
import { Dropdown } from '../Dropdown';
import { useAuth } from '../../hooks/useAuth';
import { IoLanguage } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export const Navbar = ({ positionRelative, bgTransparent }: { positionRelative?: boolean, bgTransparent?: boolean }) => {

    const auth = useAuth();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        auth.isLogged().then((isLogged) => {
            if (isLogged) {
                dropdownLinks.push({ text: 'Sair', onClick: () => window.location.href = '/logout' })
            }
        })
    })

    const dropdownLinks = [
        { text: t('navbarDropdownDashboard'), onClick: () => window.location.href = '/dashboard' },
        { text: t('navbarPlatform'), onClick: () => document.open('https://app.biosat.agr.br', '_blank', 'noopener') },
        { text: t('navbarAPI'), onClick: () => document.open('https://safra.biosat.agr.br/docs/', '_blank', 'noopener') },
    ]

    return (
        <Nav
            style={{
                position: positionRelative ? 'relative' : 'fixed',
                background: bgTransparent ? 'transparent' : 'linear-gradient(180deg, #07130A 55.21%, rgba(7, 19, 10, 0) 100%);',
            }}
        >
            <Logo href={'/'}>
                <img src={LogoImg} alt="Logo" />
            </Logo>

            <Menu>
                <MenuItem>
                    <MenuLink href={'/#soluctions'}>{t('navbarSolution')}</MenuLink>
                </MenuItem>

                <MenuItem>
                    <MenuLink href={'/#how-works'}>{t('navbarHowWorks')}</MenuLink>
                </MenuItem>

                <MenuItem>
                    <MenuLink href={'/#services'}>{t('navbarServices')}</MenuLink>
                </MenuItem>

                <MenuItem>
                    <MenuLink href={'/#partners'}>{t('navbarPartners')}</MenuLink>
                </MenuItem>

                <MenuItem>
                    <MenuLink href={'/#contacts'}>{t('navbarContact')}</MenuLink>
                </MenuItem>

                <Dropdown
                    links={dropdownLinks}
                >
                    {t('navbarDropdown')}
                </Dropdown>
            </Menu>

            <LanguageContainer>
                <Dropdown
                    links={[
                        {
                            text: 'Português', onClick: () => {
                                localStorage.setItem('lang', 'pt');

                                window.location.reload();
                            }
                        },
                        {
                            text: 'English', onClick: () => {
                                localStorage.setItem('lang', 'en');

                                window.location.reload();
                            }
                        },
                    ]}
                >
                    <Language>
                        <IoLanguage />
                        {i18n.language === 'pt' ? 'PT' : 'EN'}
                    </Language>
                </Dropdown>
            </LanguageContainer>
        </Nav>
    )
}

/* const Dropdown = () => {
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    const onDesconnect = () => {

        setIsLoading(true);

        auth.logout().then(() => {
            localStorage.clear();

            window.location.reload();
        }).catch(() => {
            setIsLoading(false);
            toast.error('Erro ao deslogar');
        })
    }

    const handleDashboard = () => {
        window.location.href = '/dashboard';
    }

    return (
        <ProfileDropdown>
            <ToastContainer />

            {
                isLoading ? (
                    <div style={{ height: "35px", width: "35px", margin: "0 auto" }}><Loading /></div>
                ) : (
                    <>
                        <ProfileDropdownItem
                            onClick={handleDashboard}
                        >Painel</ProfileDropdownItem>
                        <ProfileDropdownItem onClick={onDesconnect}>Sair</ProfileDropdownItem>
                    </>
                )
            }
        </ProfileDropdown>
    )
}
 */