export function validateCpfCnpj(input: string): boolean {
    let cpfRegex = /^\d{11}$/;
    let cnpjRegex = /^\d{14}$/;
  
    if (cpfRegex.test(input)) {
      // Validate CPF
      let sum = 0;
      let rest;
      for (let i = 1; i <= 9; i++) {
        sum = sum + parseInt(input.substring(i - 1, i)) * (11 - i);
      }
      rest = (sum * 10) % 11;
      if ((rest === 10) || (rest === 11)) {
        rest = 0;
      }
      if (rest !== parseInt(input.substring(9, 10))) {
        return false;
      }
      sum = 0;
      for (let i = 1; i <= 10; i++) {
        sum = sum + parseInt(input.substring(i - 1, i)) * (12 - i);
      }
      rest = (sum * 10) % 11;
      if ((rest === 10) || (rest === 11)) {
        rest = 0;
      }
      if (rest !== parseInt(input.substring(10, 11))) {
        return false;
      }
      return true;
    } else if (cnpjRegex.test(input)) {
      // Validate CNPJ
      let tamanho = input.length - 2;
      let numeros = input.substring(0,tamanho);
      let digitos = input.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado !== parseInt(digitos.charAt(0))) {
        return false;
      }
      tamanho = tamanho + 1;
      numeros = input.substring(0,tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado !== parseInt(digitos.charAt(1))) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }