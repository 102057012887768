import { FooterLinks, Footer as FooterContainer, Stack, FooterLogo, Link } from "./styles"
import Logo from '../../assets/logo_biosat2.svg';
import { ImWhatsapp } from 'react-icons/im';
import { MdAlternateEmail } from 'react-icons/md';
import { BsLinkedin, BsInstagram } from 'react-icons/bs';
import { BiLinkExternal } from 'react-icons/bi'
import { useTranslation } from "react-i18next";

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <FooterContainer>
            <Stack
                direction={"row"}
                alignItems={"flex-start"}
                other={"@media (max-width: 800px) { flex-direction: column-reverse; }"}
            >
                <Stack
                    direction={"column"}
                    alignItems={"center"}
                    justifyContent={"space-evenly"}
                    style={{ height: "100%", width: "100%", padding: "0 2rem", alignSelf: "flex-end" }}
                >
                    <FooterLogo>
                        <img src={Logo} alt="Logo" />
                    </FooterLogo>

                    <p style={{ marginBottom: "1rem" }}>
                        {t('copyRight')}
                    </p>
                </Stack>

                <FooterLinks
                    id="contacts"
                >
                    <h3>
                        {t('navbarContact')}
                    </h3>

                    <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        style={{ height: "100%" }}
                    >
                        <Link href="https://wa.me/5543988645821" target="_blank" rel="noreferrer">
                            <ImWhatsapp style={{ color: "#25D366" }} />
                            <span><p>{t('footerPhone')}: +55 (43) 98864-5821</p></span>
                        </Link>
                    </Stack>

                    <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        style={{ height: "100%" }}
                    >
                        <Link href={"mailto:contato@biosat.agr.br"}>
                            <MdAlternateEmail style={{ color: "#FFF" }} />
                            <span><p>E-mail: contato@biosat.agr.br</p></span>
                        </Link>
                    </Stack>

                    <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        style={{ width: "100%" }}
                    >
                        <Link href="https://www.linkedin.com/company/biosat-agricultura/" target="_blank" rel="noreferrer">
                            <BsLinkedin style={{ paddingRight: "1rem", color: "#0e76a8" }} />
                        </Link>

                        <Link href="https://www.instagram.com/biosat.agr/" target="_blank" rel="noreferrer">
                            <BsInstagram style={{ paddingRight: "1rem", color: "#FFF" }} />
                        </Link>
                    </Stack>
                </FooterLinks>

                <FooterLinks>
                    <h3>
                        Links
                    </h3>

                    <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        style={{ height: "100%" }}
                    >
                        <Link href={"/blog"} target="_blank">
                            <BiLinkExternal style={{ color: "#FFF" }} />
                            <span><p>Blog</p></span>
                        </Link>
                    </Stack>

                    <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        style={{ height: "100%" }}
                    >
                        <Link href={"https://app.biosat.agr.br"} target="_blank">
                            <BiLinkExternal style={{ color: "#FFF" }} />
                            <span><p>{t('footerLinks.0')}</p></span>
                        </Link>
                    </Stack>

                    <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                    >
                        <Link href={"https://www.biosat.agr.br/pricing/platform"} target="_blank">
                            <BiLinkExternal style={{ color: "#FFF" }} />
                            <span><p>{t('footerLinks.1')}</p></span>
                        </Link>
                    </Stack>

                    <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                    >
                        <Link href={"https://www.biosat.agr.br/pricing/safra"} target="_blank">
                            <BiLinkExternal style={{ color: "#FFF" }} />
                            <span><p>{t('footerLinks.2')}</p></span>
                        </Link>
                    </Stack>

                    <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        style={{ height: "100%" }}
                    >
                        <Link href={"https://safra.biosat.agr.br/docs"} target="_blank">
                            <BiLinkExternal style={{ color: "#FFF" }} />
                            <span><p>{t('footerLinks.3')}</p></span>
                        </Link>
                    </Stack>
                </FooterLinks>
            </Stack>
        </FooterContainer>
    )
}