export const EN = {
    en: {
        translation: {
            'navbarSolution': 'Solutions',
            'navbarHowWorks': 'How works',
            'navbarServices': 'Services',
            'navbarPartners': 'Partners',
            'navbarContact': 'Contact',
            'navbarDropdown': 'Access',
            'navbarDropdownDashboard': 'Customer area',
            'navbarPlatform': 'Platform',
            'navbarAPI': 'API Documentation',
            'presentationDescriptionSpan': 'Itensify',
            'presentationDescription': 'your results with',
            'presentationTitle': 'Precision agriculture',
            'presentationDescriptionMain1': '',
            'presentationDescriptionMain2': 'Biosat ',
            'presentationDescriptionMain3': 'is a platform that aims to assist producers and agricultural consultants, to produce with greater',
            'presentationDescriptionMain4': ' sustainability, ',
            'presentationDescriptionMain5': 'seeking to increase productivity, economic return and reduce environmental impacts.',
            'solutionsTitle': 'Solutions',
            'solution1Title': 'Soil sampling mode',
            'solution1Text1': 'From the georeferenced soil sample it is possible',
            'solution1Text2': ' manage ',
            'solution1Text3': 'data with greater',
            'solution1Text4': 'practicality',
            'solution1Text5': ', in addition to facilitating the generation of reports and data consultation.',
            'solution2Title': 'Productivity map',
            'solution2Text1': 'From the',
            'solution2Text2': ' most modern satellites ',
            'solution2Text3': 'on the market identify productive spots in an easy and efficient way, ',
            'solution2Text4': 'receive ',
            'solution2Text5': 'maps to monitor productivity and analyze the history of development of your crop.',
            'solution3Title': 'Multiple devices',
            'solution3Text': 'Register multiple properties to store and view the data of your crops in a single account, in addition, share access with as many collaborators as you wish in a practical and personalized way.',
            'solution4Title': 'Follow me function',
            'solution4Text1': 'Identified areas with differentiated development? The',
            'solution4Text2': ' Follow me ',
            'solution4Text3': 'function uses your location via GPS, to delimit the best route through the field to the point of interest.',
            'solution5Title': 'Climatic monitoring',
            'solution5Text': 'Develop a strategic planning for your future agricultural activities with the monitoring of weather conditions in real time.',
            'howWorksTitle': 'How works',
            'howWorksHeaderTitle1': 'Register',
            'howWorksHeaderTitle2': 'Data capture',
            'howWorksHeaderTitle3': 'Monitoring',
            'howWorksHeaderTitle4': 'Analysis',
            'howWorksInfo': {
                'Cadastro': {
                    funtionality: {
                        '0': 'Choose the plan that best suits you',
                        '1': 'Register on our website',
                        '2': 'Delimit your field through our interactive map',
                        '3': 'Register the crops, planting and harvest dates of your crops',
                        '4': 'Ready! You can now start using our service',
                    }
                },
                'Captura de dados': {
                    funtionality: {
                        '0': 'Our application integrates the best satellites on the market, use to register data from your field',
                        '1': 'Import the results of the georeferenced soil samples with greater ease',
                        '2': 'The data will be analyzed by our server',
                        '3': 'Receive NDVI maps to monitor the development and soil moisture of your field',
                        '4': 'Receive images of your field in real time',
                    }
                },
                Monitoramento: {
                    funtionality: {
                        '0': "Monitor the conditions of your planting in real time",
                        '1': "Monitor the changes in the climate in each field",
                        '2': "Check the amount of clouds and weather events in real time in detail",
                        '3': "View historical temperature, precipitation and pluviometry by field"
                    }
                },
                'Análise': {
                    funtionality: {
                        '0': "Analyze the development of your field, through the data obtained by our system and compare them with previous dates",
                        '1': "Obtain maps for a precise diagnosis of your field, from the analyzes of georeferenced soil samples",
                        '2': "Consult the maps of fertility and variable rate application for a precise diagnosis of your field",
                        '3': "Receive graphs, visualize the results with greater practicality and compare them with previous crops",
                        '4': "Determine the solutions in a precise way"
                    }
                }
            },
            'servicesTitle': 'Services',
            'servicesTitle1': 'Biosat Platform',
            'servicesTitle2': 'Safra API',
            'servicesInfo': {
                '0': {
                    '0': "Allows crop monitoring in real time",
                    '1': "Through georeferenced soil analysis, obtain maps of application by variable rate",
                    '2': "Use maps for a more precise application of inputs",
                    '3': "Reduce the costs of acquisition and application of inputs",
                    '4': "Obtain greater use and efficiency of nutrients",
                    '5': "Use the information to solve challenges"
                },
                '1': {
                    '0': "Historical records of vegetative indices and climatic conditions",
                    '1': "15-day weather forecast per point",
                    '2': "Georeferenced data processed especially for agricultural use",
                    '3': "Geoprocessing tools that allow the calculation of area, distance and coordinate transformation with ease",
                    '4': "Easy integration with any system",
                    '5': "Technical support available"
                }
            },
            'subscriptionButton': 'Plans',
            'docsButton': 'Documentation',
            'partnersTitle': 'Partners',
            'copyRight': '© 2023 Biosat. All rights reserved.',
            'footerPhone': 'Phone',
            'footerLinks': {
                '0': 'Biosat Platform',
                '1': 'Biosat Platform Plans',
                '2': 'Safra API Plans',
                '3': 'Documentation Safra API',
            },
            'authPassword': 'Password',
            'authButton': 'Login',
            'forgotPassword': 'Forgot your password?',
            'authError': 'Email or password incorrect',
            'authErrorEmail': 'Invalid email',
            'signupText': 'Don\'t have an account?',
            'signupButton': 'Sign up',
            'forgotPasswordTitle': 'Forgot My Password',
            'forgotPasswordText': 'Enter your email address below to receive a password reset link.',
            'forgotPasswordButton': 'Send',
            'forgotPasswordBack': 'Back',
            'successSendEmail': 'An email has been sent to you with instructions to reset your password.',
            'emailNotFound': 'Email not found',
            'errorSendEmail': 'Error sending email',
            'resetPasswordLoading': 'Loading...',
            'resetPasswordError': 'invalid or expired link',
            'resetPasswordTitle': 'Reset Password',
            'resetPasswordLabel': 'New Password',
            'resetPasswordInput': 'Enter your new password',
            'confirmPasswordLabel': 'Confirm Password',
            'confirmPasswordInput': 'Confirm your new password',
            'resetPasswordButton': 'Confirm',
            'signupTitle': 'Sign up',
            'signupName': 'Name',
            'signupEmail': 'Email',
            'signupCpfCnpj': 'CPF/CNPJ',
            'signupPassword': 'Password',
            'signupConfirmPassword': 'Confirm Password',
            'signupButton2': 'Sign up',
            'signupLogin': 'Already have an account?',
            'termsText1': 'I accept the ',
            'termsText2': 'Terms and Service agreement ',
            'termsText3': 'which includes your ',
            'termsText4': 'Privacy Statement',
            'emailTerms': 'I want to receive emails about biosat products/services, feature updates, resources, partners, and special offers.',
            'platformPlans': {
                '0': {
                    'title': 'Basic Plan',
                    'benefits': {
                        '0': "4-year historical satellite image available",
                        '1': "Register properties, plots, and crops",
                        '2': "NDVI, Moisture Index, and Natural Color Images",
                        '3': "Follow-me Function",
                    }
                },
                '1': {
                    'title': "Intermediate Plan",
                    'benefits': {
                        '0': "4-year historical satellite image available",
                        '1': "Register properties, plots, and crops",
                        '2': "NDVI, Moisture Index, and Natural Color Images",
                        '3': "Follow-me Function",
                        '4': "Virtual report with quantitative analysis of vegetative indices and comparison between plots and crops",
                        '5': "Climatic history and 15-day extended forecast specific to each plot"
                    },
                },
                '2': {
                    'title': "Advanced Plan",
                    'benefits': {
                        '0': "4-year historical satellite image available",
                        '1': "Register properties, plots, and crops",
                        '2': "NDVI, Moisture Index, and Natural Color Images",
                        '3': "Follow-me Function",
                        '4': "Virtual report with quantitative analysis of vegetative indices and comparison between plots and crops",
                        '5': "Climatic history and 15-day extended forecast specific to each plot",
                        '6': "Soil sampling function to collect and interpret georeferenced soil analyses",
                        '7': "Recommendation maps based on culture, soil conditions, and laboratory analysis results"
                    },
                }
            },
            'platformPlanPrice': 'Starting from ',
            'platformPlanPrice2': ' /month',
            'platformTitle1': 'Platform ',
            'platformTitle2': 'Biosat',
            'platformDescription1': 'Remote sensing is a technology that promotes the acquisition of images and data of the Earth surface. This technology has enabled the development of ',
            'platformDescription2': 'Precision Agriculture. ',
            'platformDescription3': 'BIOSAT uses images obtained through satellite monitoring to provide a complete spectral characterization of an area, allowing its digital visualization. The BIOSAT platform makes it possible to have a complete view of your property, providing high-resolution images, facilitating the collection of accurate data and offering ease in detecting land use changes.',
            'planTitle': 'Get to Know Our Plans',
            'benefitsTitle': 'Benefits',
            'whatsAppButton': 'Request trough WhatsApp and get a free month!',
            'hectareInput': 'ENTER THE AREA IN HECTARES',
            'quoteButton': 'MAKE A QUOTE',
            'selectPlan': 'Select a Plan',
            'price': 'Price',
            'modalTitle': 'We Have a Offer for you!',
            'modalDescription': 'Get in touch with our sales team for special pricing!',
            'apiDescription': 'API (Application Programming Interface) is a tool that allows data transmission between various software programs through programming code language. Through our API, you can easily implement satellite images, vegetation indices, and meteorological data into production development analyses.',
            'apiPlans': {
                '0': {
                    'title': 'Basic Plan',
                    'benefits': {
                        '0': "40+ years of local weather conditions historical data",
                        '1': "5+ years of historical data for over 10 vegetation indices such as NDVI, SAVI, EVI",
                        '2': "Monitoring of heavy clouds",
                        '3': "Cloud coverage index for Sentinel-2 images with over 90% accuracy",
                        '4': "Geoprocessing functions such as area calculation and geographic coordinate transformation",
                        '5': "10,000 Processing Units (PU) per month",
                        '6': "100 Processing Units (PU) per minute"
                    }
                },
                '1': {
                    'title': "Intermediate Plan",
                    'benefits': {
                        '0': "40+ years of local weather conditions historical data",
                        '1': "5+ years of historical data for over 10 vegetation indices such as NDVI, SAVI, EVI",
                        '2': "Cloud coverage index for Sentinel-2 images with over 90% accuracy",
                        '3': "Geoprocessing functions such as area calculation and geographic coordinate transformation",
                        '4': "50,000 Processing Units (PU) per month",
                        '5': "500 Processing Units (PU) per minute"
                    }
                },
                '2': {
                    'title': "Advanced Plan",
                    'benefits': {
                        '0': "40+ years of local weather conditions historical data",
                        '1': "5+ years of historical data for over 10 vegetation indices such as NDVI, SAVI, EVI",
                        '2': "Cloud coverage index for Sentinel-2 images with over 90% accuracy",
                        '3': "Geoprocessing functions such as area calculation and geographic coordinate transformation",
                        '4': "250,000 Processing Units (PU) per month",
                        '5': "1000 Processing Units (PU) per minute"
                    }
                }
            },
            'subscribe': 'Subscribe',
            'invalidCard': 'Invalid card number',
            'invalidExpiration': 'Invalid expiration date',
            'invalidCVC': 'Invalid CVV',
            'invalidName': 'Invalid name',
            'incompleteName': 'Enter your full name',
            'checkEmailMessage': "Check your email to create your access password to the platform!",
            'paymentTitle': 'Payment',
            'cardLabel': 'Card Details',
            'cardNumber': 'Card Number',
            'cardOwner': 'Cardholder Name',
            'cardExpiration': 'Expiration Date',
            'cardCVC': 'CVV',
            'cardOwnerLabel': 'Cardholder Details',
            'cardOwnerName': 'Full Name',
            'cardOwnerEmail': 'Email',
            'cardOwnerPhone': 'Phone',
            'cardOwnerCPF': 'CPF',
            'cardOwnerAddress': 'Street Address',
            'cardOwnerCEP': 'Postal Code',
            'cardOwnerNumber': 'Number',
            'cardOwnerComplement': 'Complement',
            'paymentButton': 'Complete Purchase',
            'paymentProcessing': 'Processing payment...',
            'order': 'Your Order',
            'dashboardTabs': {
                '0': 'Your Information',
                '1': 'Payment History',
                '2': 'API Key',
                '3': 'Your Plans',
                '4': 'Your Information'
            },
            'profile': {
                'label': 'Account Information',
                'nameLabel': 'Name',
                'emailLabel': 'Email Address',
                'cpfCnpjLabel': 'CPF/CNPJ',
            },
            'paymentHistory': {
                'paymentStatus': {
                    'confirmed': 'Confirmed',
                    'pending': "Pending Payment",
                    'overdue': "Overdue Payment",
                }
            },
            'dashboardInput': 'Search Orders',
            'dashboardTable': {
                '0': 'Order Date',
                '1': 'Amount',
                '2': 'Status',
                '3': 'Due Date',
                '4': 'Payment Date',
                '5': 'Payment Type',
                '6': 'Card ending in',
                '7': 'No payments found',
            },
            'api': {
                'label': 'API Keys',
                'buttonCopy': 'Copy',
                'buttonCopied': 'Copied',
            },
            'plans': {
                'label': 'My Plans',
                'cancelPlan': 'Error cancelling plan!',
                'nextBilling': 'Next billing on',
                'platformButton': 'Access Platform',
                'apiButton': 'View Documentation',
                'changePlan': 'Change Plan',
                'cancelingPlan': 'Cancelling...',
                'cancelPlanButton': 'Cancel Plan',
            },
            'sidebar': {
                '0': 'Profile',
                '1': 'Payments',
                '2': 'API Key',
                '3': 'My Plans',
                '4': 'Logout',
            },
            'planButton': 'Choose Subscription',
            'planButton2': 'Selected Plan',
            'firstMonthFree': 'First month free',
        }
    }
}