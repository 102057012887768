import LogoImg from './logo.png';
import { BlogNav } from './styled';

export const Navbar = () => {
    return (
        <BlogNav>
            <a href="/blog">
                <img src={LogoImg} alt="Logo" />
            </a>

            <div></div>
        </BlogNav>
    )
}