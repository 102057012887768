import styled from "styled-components";

// vai mostrar um card branco no meio da tela com uma imagem de um x vermelho e uma mensagem de erro

const Container = styled.main`
    display: grid;
    width: 100%;
    height: 100vh;
    place-items: center;
    place-content: center;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    background-color: #ffffff;
    img {
        width: 100px;
        height: 100px;
        color: #0A0D13;
    }
    h1 {
        font-size: 2rem;
        font-weight: 700;
        color: #0A0D13;
    }
`;

const Button = styled.button`
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: transparent;
    color: #000;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    margin: 10px 0;

    &:hover {
        text-decoration: underline;
    }
`

export const ErrorPage = Object.assign(Container, { Content, Button })