import { Navbar } from "../../components/Navbar";
import {
    Container,
    Presentation,
    Soluctions,
    Title,
    HowWorksSection,
    HowWorksHeader,
    HowWorksContent,
    HowWorksItem,
    HowWorksTitle,
    HowWorksContentLeft,
    List,
    ListItem,
    Stack,
    ServicesSection,
    ServicesContainer,
    ServicesTitle,
    Button,
    ServiceMobileHeader,
    PartnersSection,
    ServicePictureContainer,
    SecondaryButton
} from './styled';
import SoluctionIcon from '../../assets/soluctionsIcon.svg';
import SoluctionIcon2 from '../../assets/soluctionsIcon2.svg';
import SoluctionIcon3 from '../../assets/soluctionsIcon3.svg';
import SoluctionIcon4 from '../../assets/soluctionsIcon4.svg';
import SoluctionIcon5 from '../../assets/soluctionsIcon5.svg';
import Success from '../../assets/success.svg';
import { useRef, useState } from "react";
import { howWorksInfo, PartnersInfo, servicesInfo } from "./infos";
import ImageWithLoading from "../../components/ImageWithLoading";
import { useSlider } from "../../hooks/useSlider";
import { SliderContainer } from "../../components/Slider";
import { useTranslation } from "react-i18next";
import { Footer } from "../../components/Footer";


export const HomePage = () => {
    const { t } = useTranslation();

    document.title = "Biosat - Agro Digital";

    const howWorksSlideRef = useRef({ slide: 1 });
    const [selectedHowWorks, setSelectedHowWorks] = useState<string>('cadastro');
    const [selectedService, setSelectedService] = useState<number>(1);

    const sliderRef = useRef<HTMLDivElement>(null);
    const slider = useSlider(sliderRef);

    return (
        <>
            <Navbar />
            <Container>
                <Presentation>
                    <Presentation.Text>
                        <Presentation.Description>
                            <span>{t('presentationDescriptionSpan')}</span> {t('presentationDescription')}
                        </Presentation.Description>
                        <Presentation.Title>
                            {t('presentationTitle')}
                        </Presentation.Title>
                        <Presentation.Description>
                            {`${t('presentationDescriptionMain1')}`}
                            <strong>{`${t('presentationDescriptionMain2')}`}</strong>
                            {`${t('presentationDescriptionMain3')}`}
                            <span>{`${t('presentationDescriptionMain4')}`}</span>
                            {`${t('presentationDescriptionMain5')}`}
                        </Presentation.Description>
                    </Presentation.Text>
                    <Presentation.Images />
                </Presentation>

                <Soluctions id="soluctions">
                    <Soluctions.Title>
                        <div></div>
                        {t('solutionsTitle')}
                        <div></div>
                    </Soluctions.Title>

                    <Soluctions.Soluction
                        ref={sliderRef}
                        onMouseDown={slider.handleMouseDown}
                    >
                        <Soluctions.Item>
                            <img src={SoluctionIcon} alt="Soluction Icon" />

                            <Soluctions.ItemDescription>
                                <h3>
                                    {t('solution1Title')}
                                </h3>

                                <p>
                                    {t('solution1Text1')}
                                    <span> <strong>{t('solution1Text2')}</strong> </span> {t('solution1Text3')} <span> <strong>{t('solution1Text4')}</strong></span>
                                    {t('solution1Text5')}
                                </p>
                            </Soluctions.ItemDescription>
                        </Soluctions.Item>

                        <Soluctions.Item>
                            <img src={SoluctionIcon2} alt="Soluction Icon" />

                            <Soluctions.ItemDescription>
                                <h3>
                                    {t('solution2Title')}
                                </h3>

                                <p>
                                    {t('solution2Text1')} <span><strong>{t('solution2Text2')}</strong></span>
                                    {t('solution2Text3')}
                                    <span> <strong>{t('solution2Text4')}</strong></span>
                                    {t('solution2Text5')}
                                </p>
                            </Soluctions.ItemDescription>
                        </Soluctions.Item>

                        <Soluctions.Item>
                            <img src={SoluctionIcon3} alt="Soluction Icon" />

                            <Soluctions.ItemDescription>
                                <h3>
                                    {t('solution3Title')}
                                </h3>

                                <p>
                                    {t('solution3Text')}
                                </p>

                            </Soluctions.ItemDescription>
                        </Soluctions.Item>

                        <Soluctions.Item>
                            <img src={SoluctionIcon4} alt="Soluction Icon" />

                            <Soluctions.ItemDescription>
                                <h3>
                                    {t('solution4Title')}
                                </h3>

                                <p>
                                    {t('solution4Text1')}
                                    <span><strong>{t('solution4Text2')}</strong></span>
                                    {t('solution4Text3')}
                                </p>

                            </Soluctions.ItemDescription>
                        </Soluctions.Item>

                        <Soluctions.Item>
                            <img src={SoluctionIcon5} alt="Soluction Icon" />

                            <Soluctions.ItemDescription>
                                <h3>
                                    {t('solution5Title')}
                                </h3>

                                <p>
                                    {t('solution5Text')}
                                </p>

                            </Soluctions.ItemDescription>
                        </Soluctions.Item>
                    </Soluctions.Soluction>
                </Soluctions>

                <HowWorksSection id="how-works">
                    <Title>
                        <div></div>
                        {t('howWorksTitle')}
                        <div></div>
                    </Title>

                    <HowWorksHeader>
                        <HowWorksItem>
                            <HowWorksTitle
                                selected={selectedHowWorks === 'cadastro'}
                                onClick={() => {
                                    setSelectedHowWorks('cadastro')
                                    howWorksSlideRef.current.slide = 1;
                                }}
                            >
                                {t('howWorksHeaderTitle1')}
                            </HowWorksTitle>
                        </HowWorksItem>

                        <HowWorksItem style={{ width: "200px" }}>
                            <HowWorksTitle
                                selected={selectedHowWorks === 'capturaDeDados'}
                                style={{ width: "200px" }}
                                onClick={() => {
                                    setSelectedHowWorks('capturaDeDados')
                                    howWorksSlideRef.current.slide = 2;
                                }}
                            >
                                {t('howWorksHeaderTitle2')}
                            </HowWorksTitle>
                        </HowWorksItem>

                        <HowWorksItem>
                            <HowWorksTitle
                                selected={selectedHowWorks === 'monitoramento'}
                                onClick={() => {
                                    setSelectedHowWorks('monitoramento')
                                    howWorksSlideRef.current.slide = 3;
                                }}
                            >
                                {t('howWorksHeaderTitle3')}
                            </HowWorksTitle>
                        </HowWorksItem>

                        <HowWorksItem
                            onClick={() => {
                                setSelectedHowWorks('analise')
                                howWorksSlideRef.current.slide = 4;
                            }}
                        >
                            <HowWorksTitle
                                selected={selectedHowWorks === 'analise'}

                            >
                                {t('howWorksHeaderTitle4')}
                            </HowWorksTitle>
                        </HowWorksItem>
                    </HowWorksHeader>

                    <SliderContainer>
                        <SliderContainer.SliderItem
                            items={howWorksInfo.length}
                            currentSlide={howWorksSlideRef.current.slide}
                        >
                            {
                                howWorksInfo.map((item, index) => (
                                    <HowWorksContent key={index}>
                                        <HowWorksContentLeft>
                                            <ImageWithLoading src={item.image} />
                                        </HowWorksContentLeft>

                                        <List style={{ paddingLeft: "2.5rem" }}>
                                            {
                                                item.functionalities.map(
                                                    (_, index) => (
                                                        <ListItem key={index}>
                                                            <img src={Success} alt={"success"} />
                                                            {t(`howWorksInfo.${item.title}.funtionality.${index}`)}
                                                        </ListItem>
                                                    )
                                                )
                                            }
                                        </List>
                                    </HowWorksContent>
                                ))
                            }
                        </SliderContainer.SliderItem>
                    </SliderContainer>
                </HowWorksSection>

                <ServicesSection id="services">
                    <Title>
                        <div></div>
                        {t('servicesTitle')}
                        <div></div>
                    </Title>

                    <ServiceMobileHeader
                        selected={selectedService}
                    >
                        <h2
                            onClick={() => setSelectedService(1)}
                        >{t('servicesTitle2')}</h2>
                        <h2
                            onClick={() => setSelectedService(0)}
                        >{t('servicesTitle1')}</h2>
                    </ServiceMobileHeader>

                    <ServicesContainer>
                        <Stack
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                            other={`padding: 0 2rem;`}
                        >
                            <ServicesTitle>
                                {t(`servicesTitle2`)}
                            </ServicesTitle>

                            <List>
                                {
                                    servicesInfo[selectedService].infos
                                        .map((item, index) => (
                                            <ListItem key={index}>
                                                <img src={Success} alt="Success" />
                                                {t(`servicesInfo.${selectedService}.${index}`)}
                                            </ListItem>
                                        ))
                                }
                            </List>

                            <Button
                                onClick={() => window.location.href = servicesInfo[selectedService].redirectTo}
                            >
                                {t('subscriptionButton')}
                            </Button>

                            {
                                selectedService === 1 && (
                                    <SecondaryButton
                                        onClick={() => window.open('https://safra.biosat.agr.br/docs')}
                                    >
                                        {t('docsButton')}
                                    </SecondaryButton>
                                )
                            }
                        </Stack>

                        <ServicePictureContainer
                            isApi={selectedService === 1}
                        >
                            <img
                                src={servicesInfo[selectedService].photo}
                                alt="Plataforma Biosat"
                            />
                        </ServicePictureContainer>
                    </ServicesContainer>

                    <ServicesContainer
                        style={{ gridTemplateColumns: "2fr 1fr" }}
                        other={"@media (max-width: 800px) { display: none !important; }"}
                    >
                        <ServicePictureContainer
                            isApi={false}
                        >
                            <img
                                src={servicesInfo[0].photo}
                                alt="Safra API"
                            />
                        </ServicePictureContainer>

                        <Stack
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                            other={"padding: 0 2rem;"}
                        >
                            <ServicesTitle>
                                {t(`servicesTitle1`)}
                            </ServicesTitle>

                            <List>
                                {
                                    servicesInfo[0].infos
                                        .map((item, index) => (
                                            <ListItem key={index}>
                                                <img src={Success} alt="Success" />
                                                {t(`servicesInfo.0.${index}`)}
                                            </ListItem>
                                        ))
                                }
                            </List>

                            <Button
                                onClick={() => window.location.href = servicesInfo[0].redirectTo}
                            >
                                {t('subscriptionButton')}
                            </Button>
                        </Stack>
                    </ServicesContainer>
                </ServicesSection>

                <Stack id="partners">
                    <Title>
                        <div></div>
                        {t('partnersTitle')}
                        <div></div>
                    </Title>

                    <PartnersSection>
                        {
                            PartnersInfo.map((value, index) => (
                                <img
                                    key={index}
                                    src={value.image}
                                    alt={value.name}
                                />
                            ))
                        }
                    </PartnersSection>
                </Stack>

                <Footer />
            </Container>
        </>
    )
}
