import { api } from "../services/requests"
import { validateCpfCnpj } from "../util/validator/validateCpfCnpj";
import { validateEmail } from "../util/validator/validateEmail";
import { validateName } from "../util/validator/validateName";

export const useAuth = () => {

    async function isLogged() {

        const token = localStorage.getItem("@BiosatToken");

        if (!token) {
            return false;
        }

        const headers = {
            authorization: token
        }

        try {
            await api.post("/checkSession", {}, { headers });
            return true;
        } catch {
            return false;
        }
    }

    async function logout() {
        const token = localStorage.getItem("@BiosatToken") || "";

        const headers = {
            authorization: token
        }

        const response = await api.post("/logout", {}, { headers });

        if (response.status === 200) {
            localStorage.removeItem("@BiosatToken");
            return true;
        } else {
            return false;
        }
    }

    async function login(email: string, password: string) {
        return new Promise<string>((resolve, reject) => {
            const values = {
                email,
                password
            }

            const emailValidation = validateEmail(email);

            if (!emailValidation) {
                reject({
                    type: "form",
                    errors: {
                        email: "Email inválido"
                    }
                });
            }

            if (password === "" || password === undefined) {
                reject({
                    type: "form",
                    errors: {
                        password: "Senha não pode ser vazia"
                    }
                });
            }

            api.post('/login', values)
                .then(res => {
                    const { token } = res.data;
                    const { name } = res.data.user;

                    localStorage.setItem('@BiosatToken', token);
                    localStorage.setItem('@username', name);

                    resolve(token);
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        reject({
                            type: "request",
                            message: "Email ou senha incorretos"
                        });
                    } else {
                        reject({
                            type: "request",
                            message: "Erro ao fazer login"
                        });
                    }
                });
        });
    }

    async function signup(
        name: string,
        email: string,
        cpfCnpj: string,
        password: string,
        confirmPassword: string,
        check?: boolean
    ) {
        return new Promise((resolve, reject) => {
            if (check) {
                const nameValidation = validateName(name);
                const emailValidation = validateEmail(email);
                const cpfCnpjValidation = validateCpfCnpj(cpfCnpj);

                if (!nameValidation) {
                    reject({
                        type: "form",
                        errors: {
                            name: "Digite seu nome completo"
                        }
                    });
                }

                if (!emailValidation) {
                    reject({
                        type: "form",
                        errors: {
                            email: "Email inválido"
                        }
                    })
                }

                if (!cpfCnpjValidation) {
                    reject({
                        type: "form",
                        errors: {
                            cpfCnpj: "CPF/CNPJ inválido"
                        }
                    })
                }

                if (password === "") {
                    reject({
                        type: "form",
                        errors: {
                            password: "Senha não pode ser vazia"
                        }
                    })
                }

                if (confirmPassword === "") {
                    reject({
                        type: "form",
                        errors: {
                            confirmPassword: "Confirme sua senha"
                        }
                    })
                }

                if (password !== confirmPassword) {
                    reject({
                        type: "form",
                        errors: {
                            confirmPassword: "Senhas não conferem"
                        }
                    })
                }
            }

            function getUsername() {
                let text = name.split(" ")[0];

                text = text.toLowerCase();
                text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
                text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
                text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
                text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
                text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
                text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
                return text;
            }

            const data = {
                email: email,
                username: getUsername(),
                password: password,
                name: name,
                cpfCnpj: cpfCnpj,
            }

            api.post("/register", data)
                .then(() => {
                    resolve(true);
                })
                .catch((err) => {
                    reject({
                        type: "request",
                        message: err.response.data.msg
                    });
                });
        });
    }

    return { isLogged, logout, login, signup }
}