import { useLocation } from "react-router-dom";

export const useUrlParam = () => {
    
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    function getParam(param: string) {
        return params.get(param);
    }

    return { getParam }
}