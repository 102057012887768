import { Navigate, useLocation } from "react-router-dom";
import { PaymentForm } from "../../components/PaymentForm";

export function Payment() {
    document.title = "Efetuar pagamento";

    const location = useLocation();

    if (!location["state"]) {
        return <Navigate to="/error" state={{ message: "Ocorreu um erro ao processar o pagamento" }} />
    }

    const { price, itens, type, level, area } = location.state;

    const formatedPrice = price.toLocaleString('pt-BR', { minimumFractionDigits: 2 });

    window.scrollTo(0, 0);

    return (
        <PaymentForm
            price={formatedPrice}
            itens={itens}
            type={type}
            level={level}
            area={area}
        />
    )
}
