import styled, { keyframes } from "styled-components";

const fadeTop = keyframes`  
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background: #14211B;
    padding: 1rem;
    padding-right: 0;
    margin: 1rem 0;
    border-radius: 10px;    
    position: relative;
`;

const Placeholder = styled.div<{ toggleDropdown: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
    font-family: Archivo, serif;
    font-weight: 300;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    & > svg {
        transition: all 0.3s ease-in-out;

        ${props => props.toggleDropdown && `
            transform: rotate(180deg);
        `}
    }
`;

const Content = styled.div<{ toggleDropdown: boolean }>`
    display: none;
    position: absolute;
    top: 90%;
    left: 0;
    width: 200px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    z-index: 1;
    color: #ffffff;
    background: #14211B;
    animation: ${fadeTop} .3s ease-in-out;
    
    ${props => props.toggleDropdown && `
        display: flex;
        flex-direction: column;

    `}

    @media (max-width: 768px) {
        right: 0;
        left: unset;
    }
`;

const Item = styled.div`
    padding: .5rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    width: 100%;
    color: #fff;

    &:hover {
        background: #2F2F2F;
    }
`;

export const DropdownContainer = Object.assign(Container, {
    Placeholder,
    Content,
    Item
});