import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import { PT } from "./PT";
import { EN } from "./EN";

const resources = {
    ...PT,
    ...EN
}

const lang = localStorage.getItem('lang') || 'pt';

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: lang,
        interpolation: {
            escapeValue: false
        }
    })

export default i18next