import styled from "styled-components";

export const Nav = styled.nav`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 2rem 2rem 3.5rem;
    background: linear-gradient(180deg, #07130A 55.21%, rgba(7, 19, 10, 0) 100%);
    position: fixed;
    width: 100%;
    z-index: 2;
    
    @media (max-width: 800px) {
      justify-content: space-between;
    }
`;

export const Logo = styled.a`
    cursor: pointer;
    text-decoration: none;
    
    img {
        width: 150px;
        
        @media (max-width: 320px) {
            width: 100px;
        }
    }
`;

export const Menu = styled.ul`
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const MenuItem = styled.li`
    margin: 0 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
  
    @media (max-width: 800px) {
        display: none;
      
        &:last-child {
            display: flex;
        }
    }
`;

export const MenuLink = styled.a`
    text-decoration: none;
    color: #fff;
    font-family: Archivo, serif;
    font-weight: 300;
    transition: all 0.3s ease-in-out;
  
    &:hover {
      opacity: 0.8;
    }
`;

export const LanguageContainer = styled.div`

    @media (max-width: 800px) {
        display: none;
    }
`;

export const Language = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: .5rem;
    font-family: Archivo, serif;
    font-weight: 300;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
    color: #ffffff;
    font-size: .8rem;
    
    img {
        height: 10px;
        margin-right: .5rem;
    }
`;
