import { Navbar } from "../../components/Navbar";
import { Sidebar } from "./Sidebar";
import { DashboardContainer } from "./styled";
import { useUrlParam } from "../../hooks/useUrlParam";
import { useState, useEffect } from "react";
import { LoadingContainer } from "../../components/Loading/style";
import { api } from "../../services/requests";
import { PlanCard } from "./PlanCard";
import { ApiKeyField } from "./ApiKeyField";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

export const Dashboard = () => {

    const { t } = useTranslation();
    const { getParam } = useUrlParam();
    const tab = getParam("tab");

    function getTab() {
        switch (tab) {
            case "profile":
                return t('dashboardTabs.0')
            case "payments":
                return t('dashboardTabs.1')
            case "api":
                return t('dashboardTabs.2')
            case "plans":
                return t('dashboardTabs.3')
            default:
                return t('dashboardTabs.0')
        }
    }

    document.title = getTab();

    if (!tab) {
        window.location.href = "/dashboard?tab=profile";
    }

    return (
        <>
            <Navbar positionRelative={true} />

            <DashboardContainer>
                <Sidebar />

                {tab === "profile" && <ProfileContent t={t} />}
                {tab === "payments" && <PaymentsContent t={t} />}
                {tab === "api" && <ApiContent t={t} />}
                {tab === "plans" && <PlansContent t={t} />}
            </DashboardContainer>
        </>
    )
}

interface IProfileContentProps {
    name: string,
    email: string,
    cpfCnpj: string,
}

interface IGenericTranslationProps {
    t: TFunction
}

const ProfileContent = (props: IGenericTranslationProps) => {
    const { t } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [content, setContent] = useState<IProfileContentProps>({} as IProfileContentProps);

    useEffect(() => {
        setIsLoading(true);
        const token = localStorage.getItem("@BiosatToken") || "";

        const headers = {
            "authorization": token
        }

        api.get(`/user`, { headers }).then(response => {
            const { name, email, cpfCnpj } = response.data.user;
            setContent({ name, email, cpfCnpj });
            setIsLoading(false);
        })
    }, []);

    return (
        <DashboardContainer.Content
            isLoading={isLoading}
        >
            {
                isLoading ? (
                    <div style={{ width: "50px", height: "50px" }}><LoadingContainer /></div>
                ) : (
                    <>
                        <DashboardContainer.Title>
                            {t('profile.label')}
                        </DashboardContainer.Title>

                        <DashboardContainer.FormGroup>
                            <DashboardContainer.Label>{t('profile.nameLabel')}</DashboardContainer.Label>
                            <DashboardContainer.Field>
                                {content.name}
                            </DashboardContainer.Field>
                        </DashboardContainer.FormGroup>

                        <DashboardContainer.FormGroup>
                            <DashboardContainer.Label>{t('profile.emailLabel')}</DashboardContainer.Label>
                            <DashboardContainer.Field>
                                {content.email}
                            </DashboardContainer.Field>
                        </DashboardContainer.FormGroup>

                        <DashboardContainer.FormGroup>
                            <DashboardContainer.Label>{t('profile.cpfCnpjLabel')}</DashboardContainer.Label>
                            <DashboardContainer.Field>
                                {content.cpfCnpj}
                            </DashboardContainer.Field>
                        </DashboardContainer.FormGroup>
                    </>
                )
            }
        </DashboardContainer.Content>
    )
}

interface IPaymentContentProps {
    date_created: string,
    value: string,
    status: string,
    due_date: string,
    client_payment_date: string,
    payment_method: string,
}

const PaymentsContent = (props: IGenericTranslationProps) => {
    const { t } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [content, setContent] = useState<IPaymentContentProps[]>([] as IPaymentContentProps[]);

    const formatDate = (date: string) => {
        return date.split('-').reverse().join('/');
    }

    const getStatus = (status: string) => {
        switch (status) {
            case "CONFIRMED" || "RECEIVED":
                return t('paymentHistory.paymentStatus.confirmed');
            case "PENDING":
                return t('paymentHistory.paymentStatus.pending');
            case "OVERDUE":
                return t('paymentHistory.paymentStatus.overdue');
        }
    }

    const formatPrice = (value: string) => {
        const price = Number(value);

        return price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    useEffect(() => {
        const token = localStorage.getItem("@BiosatToken") || "";
        setIsLoading(true);

        const headers = {
            "authorization": token
        }

        api.get(`/payments`, { headers }).then(response => {
            const { payments } = response.data;

            // organiza os pagamentos por data de criação e depois por data de vencimento
            const paymentsSorted = payments.sort((a: IPaymentContentProps, b: IPaymentContentProps) => {
                if (a.date_created > b.date_created) {
                    return -1;
                }
                if (a.date_created < b.date_created) {
                    return 1;
                }
                if (a.due_date > b.due_date) {
                    return -1;
                }
                if (a.due_date < b.due_date) {
                    return 1;
                }
                return 0;
            });

            setContent(paymentsSorted);
            setIsLoading(false);
        })
    }, []);

    return (
        <DashboardContainer.Content
            isLoading={isLoading}
        >
            {
                isLoading ? (
                    <div style={{ width: "50px", height: "50px" }}><LoadingContainer /></div>
                ) : (
                    <>
                        <DashboardContainer.SearchInput placeholder={t('dashboardInput') || ''} />

                        <DashboardContainer.TableContainer>
                            <DashboardContainer.Table>
                                <thead>
                                    <DashboardContainer.TableRow>
                                        <DashboardContainer.TableHeader>{t('dashboardTable.0')}</DashboardContainer.TableHeader>
                                        <DashboardContainer.TableHeader>{t('dashboardTable.1')}</DashboardContainer.TableHeader>
                                        <DashboardContainer.TableHeader>{t('dashboardTable.2')}</DashboardContainer.TableHeader>
                                        <DashboardContainer.TableHeader>{t('dashboardTable.3')}</DashboardContainer.TableHeader>
                                        <DashboardContainer.TableHeader>{t('dashboardTable.4')}</DashboardContainer.TableHeader>
                                        <DashboardContainer.TableHeader>{t('dashboardTable.5')}</DashboardContainer.TableHeader>
                                    </DashboardContainer.TableRow>
                                </thead>

                                <tbody>
                                    {
                                        content.map((payment, index) => (
                                            <DashboardContainer.TableRow key={index}>
                                                <DashboardContainer.TableData>{formatDate(payment.date_created)}</DashboardContainer.TableData>
                                                <DashboardContainer.TableData>{formatPrice(payment.value)}</DashboardContainer.TableData>
                                                <DashboardContainer.TableData>{getStatus(payment.status)}</DashboardContainer.TableData>
                                                <DashboardContainer.TableData>{formatDate(payment.due_date)}</DashboardContainer.TableData>
                                                <DashboardContainer.TableData>{payment.client_payment_date ? formatDate(payment.client_payment_date) : "-"}</DashboardContainer.TableData>
                                                <DashboardContainer.TableData>{`${t('dashboardTable.6')} ${payment.payment_method}`}</DashboardContainer.TableData>
                                            </DashboardContainer.TableRow>
                                        ))
                                    }

                                    {
                                        content.length === 0 && (
                                            <DashboardContainer.TableRow>
                                                <DashboardContainer.TableData colSpan={6}>{t('dashboardTable.7')}</DashboardContainer.TableData>
                                            </DashboardContainer.TableRow>
                                        )
                                    }
                                </tbody>
                            </DashboardContainer.Table>
                        </DashboardContainer.TableContainer>
                    </>
                )
            }
        </DashboardContainer.Content>
    )
}

const ApiContent = (props: IGenericTranslationProps) => {
    const { t } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [key, setKey] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("@BiosatToken") || "";

        const headers = {
            "authorization": token
        }

        setIsLoading(true);

        api.get("/api-key", { headers }).then(response => {
            const { key } = response.data;
            setKey(key);
            setIsLoading(false);
        });
    }, []);

    return (
        <DashboardContainer.Content
            isLoading={isLoading}
        >
            <>
                {
                    isLoading ? (
                        <div style={{ width: "50px", height: "50px" }}><LoadingContainer /></div>
                    ) : (
                        <>
                            <DashboardContainer.Title>
                                {t('api.label')}
                            </DashboardContainer.Title>

                            <ApiKeyField value={key} />
                        </>
                    )
                }
            </>
        </DashboardContainer.Content>
    )
}

const PlansContent = (props: IGenericTranslationProps) => {
    const { t } = props;

    const [plans, setPlans] = useState([] as any[]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("@BiosatToken") || "";

        const headers = {
            "authorization": token
        }

        setIsLoading(true);

        api.get(`/plan`, { headers }).then(response => {
            const { plans } = response.data;
            setPlans(plans);
            setIsLoading(false);
        })

    }, [])

    return (
        <DashboardContainer.Content
            isLoading={isLoading}
        >
            {
                isLoading ? (
                    <div style={{ width: "50px", height: "50px" }}><LoadingContainer /></div>
                ) : (
                    <>
                        <DashboardContainer.Title>
                            {t('plans.label')}
                        </DashboardContainer.Title>

                        {
                            plans.map((plan, index) => (
                                <PlanCard
                                    key={index}
                                    level={plan.planLevel}
                                    type={plan.type}
                                    nextDueDate={plan.nextDueDate}
                                    value={plan.value}
                                />
                            ))
                        }
                    </>
                )
            }
        </DashboardContainer.Content>
    )
}