import {
    PresentationMain,
    Presentation,
    PlansSection,
    Title,
    Input,
    Button,
    PlanCheckout,
    PlanCheckoutContainer,
    PlanCheckoutCard,
    PlanCheckoutFooter,
    ModalBody,
    ModalLink,
    ModalLinks,
    PlanCheckoutBadge,
    Badge
} from './styled';
import { Navbar } from "../../components/Navbar";
import { useState } from "react";
import { Loading } from '../../components/Loading/';
import { useNavigate } from "react-router-dom";
import { Modal } from "../../components/Modal";
import { MdAlternateEmail } from "react-icons/md";
import { ImWhatsapp } from "react-icons/im";
import { PlanCard } from '../../components/PlanCard';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';


export const Platform = () => {
    const { t, i18n } = useTranslation();

    document.title = i18n.language === 'pt' ? "Plataforma Biosat - Planos" : "Biosat Platform - Plans";

    const [isLoading, setIsLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(0);
    const [price, setPrice] = useState<number>(0);
    const [area, setArea] = useState<string>('');
    const [showCheckout, setShowCheckout] = useState(false);
    const navigate = useNavigate();

    ReactGA.initialize('G-J1LRYTZM0H');

    const [toggleWarningModal, setToggleWarningModal] = useState(false);

    const plans = [
        {
            title: t('platformPlans.0.title'),
            benefits: [
                t('platformPlans.0.benefits.0'),
                t('platformPlans.0.benefits.1'),
                t('platformPlans.0.benefits.2'),
                t('platformPlans.0.benefits.3'),
            ],
            price: 39.90
        }, {
            title: t('platformPlans.1.title'),
            benefits: [
                t('platformPlans.1.benefits.0'),
                t('platformPlans.1.benefits.1'),
                t('platformPlans.1.benefits.2'),
                t('platformPlans.1.benefits.3'),
                t('platformPlans.1.benefits.4'),
                t('platformPlans.1.benefits.5'),
            ],
            price: 69.90
        }, {
            title: t('platformPlans.2.title'),
            benefits: [
                t('platformPlans.2.benefits.0'),
                t('platformPlans.2.benefits.1'),
                t('platformPlans.2.benefits.2'),
                t('platformPlans.2.benefits.3'),
                t('platformPlans.2.benefits.4'),
                t('platformPlans.2.benefits.5'),
                t('platformPlans.2.benefits.6'),
                t('platformPlans.2.benefits.7'),
            ],
            price: 99.90
        }
    ]

    const calculatePrice = (area: number) => {

        if (area > 2000) {
            setToggleWarningModal(true);

            return;
        } else if (area < 1) {
            window.alert('Digite a área em hectares');

            return;
        }

        let price;

        if (selectedPlan === 0) {
            if (area > 0 && area <= 100) {
                price = 39.90;
            } else if (area >= 101 && area <= 500) {
                price = 69.90;
            } else if (area >= 501 && area <= 2000) {
                price = 99.90;
            } else {
                price = 189.90;
            }
        } else if (selectedPlan === 1) {
            if (area > 0 && area <= 100) {
                price = 69.90;
            } else if (area >= 101 && area <= 500) {
                price = 99.90;
            } else if (area >= 501 && area <= 2000) {
                price = 129.90;
            } else {
                price = 219.90;
            }
        } else {
            if (area > 0 && area <= 100) {
                price = 99.90;
            } else if (area >= 101 && area <= 500) {
                price = 129.99;
            } else if (area >= 501 && area <= 2000) {
                price = 159.90;
            } else {
                price = 249.90;
            }
        }

        setPrice(price);

        //scroola para o componente com o id checkout
        window.scrollTo(0, document.getElementById("checkout")?.offsetTop || 0);
        setShowCheckout(true);
    }

    const handlePayment = () => {

        navigate("/payment", {
            state: {
                area: Number(area),
                price: price,
                level: selectedPlan,
                type: "PLATFORM",
                itens: [
                    {
                        title: `${plans[selectedPlan].title}`,
                        price: price
                    },
                    {
                        title: "Hectares",
                        price: Number(plans[selectedPlan].price),

                    }
                ]
            }
        })
    }

    return (
        <>
            <Navbar />

            <div>
                <PresentationMain
                    imageUrl='/platformpresentation.webp'
                >
                    <Presentation>
                        <h2>
                            {t('platformTitle1')}<span>{t('platformTitle2')}</span>
                        </h2>

                        <p>
                            {t('platformDescription1')}
                            <span>{t('platformDescription2')} </span>
                            {t('platformDescription3')}
                        </p>
                    </Presentation>
                </PresentationMain>

                <PlansSection>
                    <Title>
                        {t('planTitle')}
                    </Title>

                    <Badge>
                        {t('firstMonthFree')}
                    </Badge>

                    <div>
                        <PlanCard
                            plans={plans}
                            setSelectedPlan={setSelectedPlan}
                            selectedPlan={selectedPlan}
                            setShowCheckout={setShowCheckout}
                            type="PLATFORM"
                        />

                        <Input
                            placeholder={t('hectareInput') || ''}
                            onChange={(e) => {
                                if (showCheckout) {
                                    setShowCheckout(false);
                                }

                                setArea(e.target.value)
                            }}
                            value={area}
                        />

                        <Button
                            onClick={() => {
                                if (showCheckout) {
                                    setShowCheckout(false);
                                }

                                calculatePrice(Number(area));
                            }}
                        >{t('quoteButton')}</Button>
                    </div>
                </PlansSection>

                <PlanCheckout id={"checkout"}>
                    <PlanCheckoutContainer>
                        <div>
                            <PlanCheckoutCard>
                                {
                                    showCheckout ? (
                                        <>
                                            <h3>{plans[selectedPlan].title}</h3>
                                        </>
                                    ) : (
                                        <p>{t('selectPlan')}</p>
                                    )
                                }
                            </PlanCheckoutCard>

                            <PlanCheckoutCard>
                                {
                                    showCheckout ? (
                                        <>
                                            <h3>Área</h3>
                                            <p>{area} hectares</p>
                                        </>
                                    ) : (
                                        <p>{t('selectPlan')}</p>
                                    )
                                }
                            </PlanCheckoutCard>

                            <PlanCheckoutCard>
                                {
                                    showCheckout ? (
                                        <>
                                            <PlanCheckoutBadge>
                                                {t('firstMonthFree')}
                                            </PlanCheckoutBadge>
                                            <h3>{t('price')}</h3>

                                            <p>
                                                <span>
                                                    {price.toLocaleString('pt-br', {
                                                        style: 'currency',
                                                        currency: 'BRL'
                                                    })} {t('platformPlanPrice2')}
                                                </span>
                                            </p>
                                        </>
                                    ) : (
                                        <p>{t('selectPlan')}</p>
                                    )
                                }
                            </PlanCheckoutCard>
                        </div>
                    </PlanCheckoutContainer>

                    <PlanCheckoutFooter>
                        <Button
                            disabled={!showCheckout || isLoading}
                            onClick={handlePayment}
                        >
                            {
                                isLoading ? (
                                    <div style={{ height: "35px", width: "35px" }}><Loading /></div>
                                ) : (
                                    t('subscribe')
                                )
                            }
                        </Button>
                    </PlanCheckoutFooter>
                </PlanCheckout>

                <Modal
                    isOpen={toggleWarningModal}
                    onClose={() => setToggleWarningModal(false)}
                    title={t('modalTitle')}
                >
                    <ModalBody>
                        {t('modalDescription')}

                        <ModalLinks>
                            <ModalLink href={"mailto:contato@biosat.agr.br"}>
                                <MdAlternateEmail style={{ color: "#FFF" }} />
                                <span><p>E-mail: contato@biosat.agr.br</p></span>
                            </ModalLink>

                            <ModalLink href="https://wa.me/5543988645821" target="_blank" rel="noreferrer">
                                <ImWhatsapp style={{ color: "#FFF" }} />
                                <span><p>{t('footerPhone')}: +55 (43) 98864-5821</p></span>
                            </ModalLink>
                        </ModalLinks>
                    </ModalBody>
                </Modal>
            </div>
        </>
    )
}
