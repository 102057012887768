import styled from "styled-components";

const Main = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background: #14211B;
`;

export const LogoutContainer = Object.assign(Main, {});

const ErrorMain = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ErrorText = styled.p`
    color: #fff;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;

    @media (max-width: 400px) {
        font-size: 1.2rem;
    }
`;

const ErrorButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
`;

const ErrorButton = styled.button`
    width: 250px;
    height: 40px;
    background: #F27E30;
    font-family: Archivo, serif;
    font-weight: 700;
    color: #FFF;
    transition: .2s;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    margin: 0 .5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: .8;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const ErrorContainer = Object.assign(ErrorMain, {
    Text: ErrorText,
    Buttons: ErrorButtons,
    Button: ErrorButton,
});