import { MouseEventHandler, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import {
    Container,
    AuthFormCard,
    Input,
    Label,
    Link,
    Button,
    Footer,
    Text,
} from './styled';
import Logo from '../../assets/logo.svg';
import { ToastContainer } from 'react-toastify';
import { useForm } from '../../hooks/useForm';
import { validateEmail } from '../../util/validator/validateEmail';
import { Loading } from '../../components/Loading';
import { useUrlParam } from '../../hooks/useUrlParam';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';

interface ILogin {
    email: string;
    password: string;
}

const Login = () => {

    const { t, i18n } = useTranslation();

    document.title = i18n.language === 'pt' ? "Faça seu login" : "Login";

    const initial: ILogin = { email: "", password: "" }

    const { form, onChange } = useForm<ILogin>(initial);
    const [errors, setErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const params = useUrlParam();
    const { login } = useAuth();

    const redirect = params.getParam("redirect");

    const navigate = useNavigate();

    const onLogin: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();

        const values: ILogin = {
            email: form.email,
            password: form.password
        }

        setIsLoading(true);

        login(values.email, values.password)
            .then(() => {
                setIsLoading(false);

                if (redirect) {
                    navigate(redirect);
                } else {
                    navigate('/');
                }
            })
            .catch(err => {
                setIsLoading(false);
                
                if (err.type === 'form') {
                    setErrors(err.errors);
                } else if (err.type === 'request') {
                    toast.error(err.message);
                }
            });
    }


    return (
        <Container style={{ height: "100vh" }}>
            <ToastContainer />

            <AuthFormCard>
                <img src={Logo} alt={"logo da empresa"} />

                <Label htmlFor={"email-input"}>E-mail</Label>
                <Input
                    placeholder={"nome@email.com"}
                    type={"email"}
                    id={"email-input"}
                    value={form.email}
                    onChange={(e) => onChange(e.target.value, "email")}
                    onBlur={e => {
                        const result = validateEmail(e.target.value);

                        if (!result) {
                            setErrors({ ...errors, email: t('authErrorEmail') });
                        } else if (errors.email) {
                            setErrors({ ...errors, email: "" });
                        }
                    }}
                    error={errors.email}
                />

                <Label htmlFor={"password-input"}>{t('authPassword')}</Label>
                <Input
                    placeholder={"******"}
                    type={"password"}
                    id={"password-input"}
                    value={form.password}
                    onChange={(e) => onChange(e.target.value, "password")}
                    error={errors.password}
                />

                <Link href={"/recover"}>{t('forgotPassword')}</Link>

                <Button
                    type={"submit"}
                    onClick={onLogin}
                    disabled={isLoading}
                >
                    {
                        isLoading ? (
                            <div style={{ height: "35px", width: "35px" }}><Loading /></div>
                        ) : (
                            t('authButton')
                        )
                    }
                </Button>

                <Footer>
                    <Text>{t('signupText')}</Text>
                    <Link href={"/signup"}>{t('signupButton')}</Link>
                </Footer>
            </AuthFormCard>
        </Container>
    );
};

export default Login;
