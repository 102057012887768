import { MouseEventHandler, useState } from 'react';
import {
    Container,
    AuthFormCard,
    SubTitle,
    Input,
    Button,
    Text,
    Link,
    Footer
} from './styled';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.svg'
import { validateEmail } from '../../util/validator/validateEmail';
import { toast, ToastContainer } from 'react-toastify';
import { useForm } from '../../hooks/useForm';
import { Loading } from '../../components/Loading';
import { api } from '../../services/requests';
import { useTranslation } from 'react-i18next';

const Recover = () => {
    const {t, i18n} = useTranslation();

    document.title = i18n.language === 'pt' ? "Recuperar senha" : "Recover password";

    const [errors, setErrors] = useState<any>({});
    const { form, onChange } = useForm<{ email: string }>({ email: "" });
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();

        const emailValidation = validateEmail(form.email);

        if (!emailValidation) {
            setErrors({ email: "Email inválido" });
            return;
        }

        setIsLoading(true);
        api.post("/resetPassword/sendEmail", { user_email: form.email }).then(res => {

            if (res.status === 200) {
                toast.success(t('successSendEmail'));
            }

            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);

            if (err.response.status === 404) {
                toast.warning(t('emailNotFound'));
                return;
            }

            toast.error(t('errorSendEmail'));
        });
    }

    const navigate = useNavigate();

    return (
        <Container style={{ height: "100vh" }}>
            <ToastContainer />

            <AuthFormCard>
                <img src={Logo} alt="logo da empresa" />

                <SubTitle>{t('forgotPasswordTitle')}</SubTitle>
                <Text>{t('forgotPasswordText')}</Text>

                <Input
                    style={{ marginTop: 20 }}
                    placeholder={"Email"}
                    value={form.email}
                    onChange={e => onChange(e.target.value, "email")}
                    onBlur={e => {
                        const result = validateEmail(e.target.value);

                        if (!result) {
                            setErrors({ ...errors, email: "Email inválido" });
                        } else if (errors.email) {
                            setErrors({ ...errors, email: "" });
                        }
                    }}
                    error={errors.email}
                />

                <Button
                    onClick={onSubmit}
                    disabled={isLoading}
                >
                    {
                        isLoading ? (
                            <div style={{ height: "35px", width: "35px" }}><Loading /></div>
                        ) : (
                            t('forgotPasswordButton')
                        )
                    }
                </Button>

                <Footer>
                    <Link onClick={() => navigate('/login')}>{t('forgotPasswordBack')}</Link>
                </Footer>
            </AuthFormCard>
        </Container>

    );
};

export default Recover;
