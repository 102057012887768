export function validadeExpirationDate(input: string) {
    if (input.length !== 4) {
        return false;
    }

    let month = input.slice(0, 2);
    let year = input.slice(2);

    if (month.length !== 2 || year.length !== 2) {
        return false;
    }

    if (parseInt(month) > 12 || parseInt(month) < 1) {
        return false;
    }

    if (parseInt(year) < 23) {
        return false;
    }

    return true;

}