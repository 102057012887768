import styled from "styled-components";

const Main = styled.div`
    width: 300px;
    padding: 1rem;
    background: #14211B;
    border-radius: 10px;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 300px) {
        width: 100%;
        margin-bottom: 1rem;
    }
`;

const Title = styled.h3`
    font-family: Archivo, serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #fff;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 16px;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Text = styled.p<{ isValue?: boolean }>`
    font-family: Poppins, serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: #fff;
    margin-bottom: 1rem;

    ${({ isValue }) => isValue && `
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        margin-top: 1rem;

        @media (max-width: 768px) {
            font-size: 16px !important;
            line-height: 16px !important;
        }
    `}
    
    @media (max-width: 768px) {
        font-size: 10px;
        line-height: 10px;
    }
`;

const Button = styled.button`
    width: 100%;
    height: 40px;
    background: #F27E30;
    padding: 8px 24px;
    font-family: Archivo, serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFF;
    margin: .4rem auto;
    transition: .2s;
    border-radius: 5px;

    &:hover {
        opacity: .8;
    }
`;

const SecondaryButton = styled(Button)`
    background: transparent;
    border: 2px solid #F27E30;
    color: #F27E30;

    &:hover {
        opacity: .8;
    }
`;

const CancelButton = styled(Button)`
    background: transparent;
    // deixa a borda vermelha
    background: #8A1515;
    color: #FFF;

    &:hover {
        opacity: .8;
    }
`;

export const PlanCard = Object.assign(Main, {
    Title,
    Row,
    Text,
    Button,
    SecondaryButton,
    CancelButton
});