import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingContainer = styled.div`
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  
    &::after {
      content: " ";
      display: block;
      width: calc(100% - 1rem);
      height: calc(100% - 1rem);
      border-radius: 50%;
      border: 5px solid;
      border-color: #62C77A transparent #62C77A transparent;
      animation: ${rotate} 1.1s linear infinite;
    }
`;
