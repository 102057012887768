import styled from "styled-components";

const Main = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: 0 auto;
    width: 70%;
    height: fit-content;
    border-radius: 5px;
    gap: 25px;
    text-align: left;

    @media (max-width: 999px) {
        width: 98%;
    }

    & > img {
        border-radius: 5px;
        width: 100%;
        height:300px;
        object-fit: cover;
    }

    & > h1 {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
        color: #07130A;
        width: 100%;
        font-family: Archivo, sans-serif;
    }

    & p, & li, & span {
        font-size: 18px;
        font-weight: 400;
        color: #07130A;
        width: 100%;
        font-family: Inter, sans-serif;
        letter-spacing: 0.5px;
        line-height: 1.5rem;
        margin-bottom: 1rem;
    }

    & ul {
        padding-left: 2rem;
        list-style: disc;
    }
`;

const MeetButton = styled.a`
    background-color: #62C77A;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;

    &:hover {
        filter: brightness(0.9);
    }
`;

export const PostComplete = Object.assign(Main, {
    MeetButton
})