import { useNavigate } from 'react-router-dom';
import { useUrlParam } from '../../../hooks/useUrlParam';
import { Sidebar as SidebarContainer } from './styled';
import { useTranslation } from 'react-i18next';

export const Sidebar = () => {

    const name = localStorage.getItem("@username");
    const navigate = useNavigate();
    const { getParam } = useUrlParam();
    const tab = getParam("tab");
    const { t } = useTranslation();

    return (
        <SidebarContainer>
            <div>
                <SidebarContainer.Title>
                    {name}
                </SidebarContainer.Title>

                <SidebarContainer.Menu>
                    <SidebarContainer.MenuItem
                        onClick={() => navigate("?tab=profile")}
                        active={tab === "profile"}
                    >
                        {t('sidebar.0')}
                    </SidebarContainer.MenuItem>

                    <SidebarContainer.MenuItem
                        onClick={() => navigate("?tab=payments")}
                        active={tab === "payments"}
                    >
                        {t('sidebar.1')}
                    </SidebarContainer.MenuItem>

                    <SidebarContainer.MenuItem
                        onClick={() => navigate("?tab=api")}
                        active={tab === "api"}
                    >
                        {t('sidebar.2')}
                    </SidebarContainer.MenuItem>

                    <SidebarContainer.MenuItem
                        onClick={() => navigate("?tab=plans")}
                        active={tab === "plans"}
                    >
                        {t('sidebar.3')}
                    </SidebarContainer.MenuItem>

                </SidebarContainer.Menu>
            </div>

            <SidebarContainer.LogoutButton
                onClick={() => navigate("/logout")}
            >{t('sidebar.4')}</SidebarContainer.LogoutButton>
        </SidebarContainer>
    )
}