import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ErrorImage from '../../assets/bx_error-circle.svg';
import { ErrorPage } from "./styled";

export const Error = () => {
    const location = useLocation();
    const navigate = useNavigate();

    if (!location["state"]) {
        return <Navigate to="/" />
    }

    const { message } = location.state;

    function redirectToHome() {
        navigate("/")
    }

    return (
        <ErrorPage>
            <ErrorPage.Content>
                <img src={ErrorImage} alt="Error" />
                <h1>{message}</h1>

                <ErrorPage.Button onClick={redirectToHome}>
                    Voltar para a página inicial
                </ErrorPage.Button>
            </ErrorPage.Content>
        </ErrorPage>
    )
}