import styled from "styled-components";

const MainSidebar = styled.aside`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 2rem 1rem;
    background: #14211B;
    width: 100%;
    height: 80vh;
    z-index: 1;

    div {
        width: 100%;
        
        @media (max-width: 768px) {
            overflow: hidden;
        }
    }

    @media (max-width: 768px) {
        width: auto;
        height: 150px;
        background: transparent;
    }
`;

const Title = styled.h2`
    font-family: Archivo, serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #F27E30;

    @media (max-width: 768px) {
        display: none;
    }
`;

const Menu = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1rem 0;

    @media (max-width: 768px) {
        flex-direction: row;
        padding: 0;
    }
`;

const MenuItem = styled.li<{ active?: boolean }>`
    width: 100%;
    font-family: Archivo, serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    border-radius: 5px;
    color: #FFF;
    padding: .5rem .2rem;
    margin: .2rem 0%;
    transition: .2s;
    cursor: pointer;
    background: ${({ active }) => active ? '#61C67A' : 'transparent'};
    color: ${({ active }) => active ? '#14211B' : '#FFF'};

    &:hover {
        background: #61C67A;
        color: #14211B;
    }

    @media (max-width: 768px) {
        text-align: center;
    }
`;

const LogoutButton = styled.button`
    width: 100%;
    border: 1px solid #C3CDD9;
    border-radius: 4px;
    background-color: transparent;
    color: #FFF;
    padding: .5rem;
    font-family: Archivo, serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #FFFFFF;

`;

export const Sidebar = Object.assign(MainSidebar, {
    Title,
    Menu,
    MenuItem,
    LogoutButton
});