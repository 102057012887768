import {
	PresentationMain,
	Presentation,
	PlansSection,
	Title,
	Button,
	Badge
} from './styled';
import { Navbar } from "../../components/Navbar";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { Loading } from '../../components/Loading';
import { PlanCard } from '../../components/PlanCard';
import { useTranslation } from 'react-i18next';

export const Safra = () => {
	const { t, i18n } = useTranslation();

	document.title = i18n.language === 'pt' ? "API Safra - Planos" : "Safra API - Plans";

	const [selectedPlan, setSelectedPlan] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const auth = useAuth();

	const plans = [
		{
			title: t('apiPlans.0.title'),
			benefits: [
				t('apiPlans.0.benefits.0'),
				t('apiPlans.0.benefits.1'),
				t('apiPlans.0.benefits.2'),
				t('apiPlans.0.benefits.3'),
				t('apiPlans.0.benefits.4'),
				t('apiPlans.0.benefits.5'),
				t('apiPlans.0.benefits.6')
			],
			price: 89.90
		}, {
			title: t('apiPlans.1.title'),
			benefits: [
				t('apiPlans.1.benefits.0'),
				t('apiPlans.1.benefits.1'),
				t('apiPlans.1.benefits.2'),
				t('apiPlans.1.benefits.3'),
				t('apiPlans.1.benefits.4'),
				t('apiPlans.1.benefits.5')
			],
			price: 249.90
		}, {
			title: t('apiPlans.2.title'),
			benefits: [
				t('apiPlans.2.benefits.0'),
				t('apiPlans.2.benefits.1'),
				t('apiPlans.2.benefits.2'),
				t('apiPlans.2.benefits.3'),
				t('apiPlans.2.benefits.4'),
				t('apiPlans.2.benefits.5'),
			],
			price: 499.90
		}
	]

	const handlePayment = () => {

		navigate("/payment", {
			state: {
				price: plans[selectedPlan].price,
				type: "API",
				level: selectedPlan,
				itens: [{
					title: `${plans[selectedPlan].title}`,
					price: plans[selectedPlan].price
				}]
			}
		});
	}

	return (
		<>
			<Navbar />

			<div>
				<PresentationMain
					imageUrl='/CropMonitoring-image-1-mob.webp'
				>
					<Presentation>
						<h2>Safra <span>API</span></h2>

						<p>
							{t('apiDescription')}
						</p>
					</Presentation>
				</PresentationMain>

				<PlansSection>
					<Title>
						{t('planTitle')}
					</Title>

					<Badge>
                        {t('firstMonthFree')}
                    </Badge>

					<div>
						<PlanCard
							plans={plans}
							setSelectedPlan={setSelectedPlan}
							type="API"
							selectedPlan={selectedPlan}
						/>

						<Button
							onClick={handlePayment}
							disabled={isLoading}
						>
							{
								isLoading ? (
									<div style={{ height: "35px", width: "35px" }}><Loading /></div>
								) : (
									t('subscribe')
								)
							}
						</Button>
					</div>
				</PlansSection>
			</div>
		</>
	)
}
