import {
    Container,
    AuthFormCard,
    Title,
    Label,
    Input,
    Box,
    BoxContent,
    Button,
    Footer,
    Link,
    CheckBox,
} from './styled';
import Logo from '../../assets/logo.svg'
import { MouseEventHandler, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { validateName } from '../../util/validator/validateName';
import { validateEmail } from '../../util/validator/validateEmail';
import { validateCpfCnpj } from '../../util/validator/validateCpfCnpj';
import { getMaskForCpfCnpj } from '../../util/mask/cpfCnpjMask';
import { Loading } from '../../components/Loading';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';

interface ICreateAccount {
    name: string,
    email: string,
    cpfCnpj: string,
    password: string,
    confirmPassword: string
}

const initialState = {
    name: '',
    email: '',
    cpfCnpj: '',
    password: '',
    confirmPassword: ''
}

const Signup = () => {
    const { t } = useTranslation();

    document.title = "Crie sua conta"

    const { form, onChange } = useForm<ICreateAccount>(initialState);
    const navigate = useNavigate();
    const [errors, setErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const { signup } = useAuth();

    const onSignup: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();

        setIsLoading(true);
        signup(
            form.name,
            form.email,
            form.cpfCnpj,
            form.password,
            form.confirmPassword
        )
            .then(() => {
                setIsLoading(false);
                toast.success("Conta criada com sucesso!");
                navigate("/login");
            })
            .catch((err) => {
                setIsLoading(false);
                
                if (err.type === 'form') {
                    setErrors(err.errors);
                } else if (err.type === 'request') {
                    toast.error(err.message);
                }
            });
    }

    return (
        <Container>
            <ToastContainer />

            <AuthFormCard style={{ maxWidth: "60vw" }}>
                <img
                    src={Logo}
                    alt={"logo da empresa"}
                    style={{ width: "300px" }}
                />

                <Title>
                    {t('signupTitle')}
                </Title>

                <Box columns={1}>
                    <BoxContent>
                        <Label htmlFor={"name-input"}>{t('signupName')} *</Label>
                        <Input
                            type={"text"}
                            id={"name-input"}
                            value={form.name}
                            onChange={(e) => onChange(e.target.value, "name")}
                            onBlur={e => {
                                const result = validateName(e.target.value);

                                if (!result) {
                                    setErrors({ ...errors, name: "Digite seu nome completo" });
                                } else if (errors.name) {
                                    setErrors({ ...errors, name: "" });
                                }
                            }}
                            error={errors.name}
                        />
                    </BoxContent>
                </Box>

                <Box columns={2}>
                    <BoxContent>
                        <Label htmlFor={"email-input"}>{t('signupEmail')} *</Label>
                        <Input
                            type={"email"}
                            id={"email-input"}
                            value={form.email}
                            onChange={(e) => onChange(e.target.value, "email")}
                            onBlur={e => {
                                const result = validateEmail(e.target.value);

                                if (!result) {
                                    setErrors({ ...errors, email: "Email inválido" });
                                } else if (errors.email) {
                                    setErrors({ ...errors, email: "" });
                                }
                            }}
                            error={errors.email}
                        />
                    </BoxContent>

                    <BoxContent>
                        <Label htmlFor={"cpfCnpj-input"}>{t('signupCpfCnpj')} *</Label>
                        <Input
                            type={"text"}
                            id={"cpfCnpj-input"}
                            value={getMaskForCpfCnpj(form.cpfCnpj)}
                            maxLength={18}
                            accept={"[0-9]"}
                            onChange={(e) => {
                                const value = e.target.value.replace(/\D/g, "");

                                onChange(value, "cpfCnpj");
                            }}
                            onBlur={e => {
                                const value = e.target.value.replace(/\D/g, "");

                                const result = validateCpfCnpj(value);

                                if (!result) {
                                    setErrors({ ...errors, cpfCnpj: "CPF ou CNPJ inválido" });
                                } else if (errors.cpfCnpj) {
                                    setErrors({ ...errors, cpfCnpj: "" });
                                }
                            }}
                            error={errors.cpfCnpj}
                        />
                    </BoxContent>
                </Box>

                <Box columns={2}>
                    <BoxContent>
                        <Label htmlFor={"password-input"}>{t('signupPassword')} *</Label>
                        <Input
                            type={"password"}
                            id={"password-input"}
                            value={form.password}
                            onChange={(e) => onChange(e.target.value, "password")}
                            onBlur={e => {
                                const result = form.confirmPassword === e.target.value || form.confirmPassword === "";

                                if (!result) {
                                    setErrors({ ...errors, confirmPassword: "Senhas não conferem" });
                                } else if (errors.confirmPassword) {
                                    setErrors({ ...errors, confirmPassword: "" });
                                }
                            }}
                            error={errors.password || errors.confirmPassword}
                        />
                    </BoxContent>

                    <BoxContent>
                        <Label htmlFor={"confirm-password-input"}>{t('signupConfirmPassword')} * <span>{errors.confirmPassword}</span></Label>
                        <Input
                            type={"password"}
                            id={"confirm-password-input"}
                            value={form.confirmPassword}
                            onChange={(e) => onChange(e.target.value, "confirmPassword")}
                            onBlur={e => {
                                const result = form.password === e.target.value;

                                if (!result) {
                                    setErrors({ ...errors, confirmPassword: "Senhas não conferem" });
                                } else if (errors.confirmPassword) {
                                    setErrors({ ...errors, confirmPassword: "" });
                                }
                            }}
                            error={errors.confirmPassword}
                        />
                    </BoxContent>
                </Box>

                <Box columns={1}>
                    <BoxContent>
                        <Label htmlFor={"terms-input"}>
                            <CheckBox
                                type={"checkbox"}
                                id={"terms-input"}
                            />
                            {t('termsText1')} <a href={"/signup"}>{t('termsText2')}</a>
                            {t('termsText3')} <a href={"/signup"}> {t('termsText4')}</a>
                        </Label>
                    </BoxContent>
                </Box>

                <Box columns={1}>
                    <BoxContent>
                        <Label htmlFor={"newsletter-input"}>
                            <CheckBox
                                type={"checkbox"}
                                id={"newsletter-input"}
                            />
                            {t('emailTerms')}
                        </Label>
                    </BoxContent>
                </Box>

                <Button
                    type={"submit"}
                    onClick={onSignup}
                    disabled={isLoading}
                >
                    {
                        isLoading ? (
                            <div style={{ height: "35px", width: "35px" }}><Loading /></div>
                        ) : (
                            t('signupButton2')
                        )
                    }
                </Button>

                <Footer style={{ alignItems: "start" }}>
                    <Link href={"/login"}>{t('signupLogin')}</Link>
                </Footer>
            </AuthFormCard>
        </Container>

    );
};

export default Signup;
