import { useEffect, useState } from "react";

export const useSlider = (sliderRef: any) => {

    const [mouseDown, setMouseDown] = useState(false);
    const [startX, setStartX] = useState(null);

    useEffect(() => {
        const handleMouseMove = (event: any) => {

            if (!mouseDown || !startX) return;

            if (mouseDown) {
                const diff = event.clientX - startX;
                const slider = sliderRef.current;
                if (slider) {
                    slider.scrollLeft -= diff;
                }
                setStartX(event.clientX);
            }
        };

        const handleMouseUp = () => {
            setMouseDown(false);
            setStartX(null);
        };

        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [mouseDown, startX, sliderRef]);

    const handleMouseDown = (event: any) => {
        event.preventDefault();
        setMouseDown(true);
        setStartX(event.clientX);
    };

    return { handleMouseDown }
}