import styled from "styled-components";

const Main = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 45px;
    background: rgba(247, 248, 252, 0.4);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin: 2rem 0;
    
    @media (max-width: 500px) {
        width: 100%;
    }
`;

const KeyField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 .5rem;
    color: #fff;
    font-family: Poppins, serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;

    @media (max-width: 500px) {
        font-size: 12px;
    }
`;

const CopyButton = styled.button<{ isCopied: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 100%;
    background: ${({ isCopied }) => isCopied ? "#00C853" : "#F27E30"};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: none;
    cursor: pointer;
    outline: none;
    transition: background .2s;
    padding: 0 2.5rem;
    font-family: Poppins, serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #fff;

    @media (max-width: 500px) {
        width: 40px;
    }
`;

const IconButton = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 100%;
    color: #fff;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
`;

export const ApiKeyContainer = Object.assign(Main, {
    KeyField,
    CopyButton,
    IconButton
});