import { useAuth } from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { ErrorContainer, LogoutContainer } from "./styled";
import { useNavigate } from "react-router-dom";

export const LogoutPage = () => {
    document.title = "Saindo..."

    const { logout } = useAuth();
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        logout().then(() => {
            navigate('/');
        }).catch(() => {
            document.title = "Ocorreu um erro ao sair da sua conta";
            setError(true);
        })
    });

    return (
        <LogoutContainer>
            {error ? <Error /> : <div style={{width: "50px", height: "50px"}}><Loading /></div>}
        </LogoutContainer>
    )
}

const Error = () => {

    const navigate = useNavigate();

    return (
        <ErrorContainer>
            <ErrorContainer.Text>
                Ocorreu um erro ao sair da sua conta.
            </ErrorContainer.Text>

            <ErrorContainer.Buttons>
                <ErrorContainer.Button
                    onClick={() => window.location.reload()}
                >
                    Tentar novamente
                </ErrorContainer.Button>

                <ErrorContainer.Button
                    onClick={() => navigate(-1)}
                >
                    Voltar
                </ErrorContainer.Button>
            </ErrorContainer.Buttons>
        </ErrorContainer>
    )
}