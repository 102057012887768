import { GLobalStyle } from './assets/globalStyle/globalStyle';
import Router from './routes/router';
import './util/translation/translations';

function App() {
  return (
    <>
      <GLobalStyle />
       <Router />
    </>
  );
}

export default App;
