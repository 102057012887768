import { PlanCard as PlanCardContainer } from "./styled";
import { useNavigate } from "react-router-dom";
import { api } from "../../../services/requests";
import { toast, ToastContainer } from "react-toastify";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IPlanLevelProps {
    level: string,
    type: string,
    nextDueDate: string,
    value: string,
}


export const PlanCard = (props: IPlanLevelProps) => {
    const {t} = useTranslation();

    const [isCancelingPlan, setIsCancelingPlan] = useState(false);

    const navigate = useNavigate();

    const getPlanLevel = (level: string) => {
        switch (level) {
            case "BASIC":
                return t('apiPlans.0.title');
            case "INTERMEDIARY":
                return t('apiPlans.1.title');
            case "ADVANCED":
                return t('apiPlans.2.title');
        }
    }

    const getPlanType = (type: string) => {
        switch (type) {
            case "PLATFORM":
                return t('servicesTitle1');
            case "API":
                return t('servicesTitle2');
        }
    }

    const formatDate = (date: string) => {
        return date.split('-').reverse().join('/');
    }

    const getPlanTypeUrl = (type: string) => {
        switch (type) {
            case "PLATFORM":
                return "/pricing/platform";
            case "API":
                return "/pricing/safra";
            default:
                return "/pricing/platform";
        }
    }

    function handleCancelPlanClick() {
        setIsCancelingPlan(true);

        const token = localStorage.getItem("@BiosatToken") || "";

        const body = {
            "type": props.type
        }

        const headers = {
            "Content-Type": "application/json",
            "authorization": token
        }

        api.post("/plan/cancel", body, { headers }).then(response => {
            window.location.reload();
            setIsCancelingPlan(false);
        }).catch(() => {
            toast.error(t('plans.cancelPlan'));
            setIsCancelingPlan(false);
        });
    }

    return (
        <PlanCardContainer>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
            />

            <PlanCardContainer.Title>
                {getPlanLevel(props.level)} - {getPlanType(props.type)}
            </PlanCardContainer.Title>

            <PlanCardContainer.Row>
                <PlanCardContainer.Text>
                    {`${t('plans.nextBilling')} ${formatDate(props.nextDueDate)}`}
                </PlanCardContainer.Text>
            </PlanCardContainer.Row>

            <PlanCardContainer.Row>
                <PlanCardContainer.Text
                    isValue={true}
                >
                    {`R$ ${props.value} ${t('platformPlanPrice2')}`}
                </PlanCardContainer.Text>
            </PlanCardContainer.Row>

            <PlanCardContainer.Row>
                <PlanCardContainer.Button
                    onClick={() => {
                        if (props.type === 'PLATFORM') {
                            window.open("https://app.biosat.agr.br/")
                        } else if (props.type === 'API') {
                            window.open("https://safra.biosat.agr.br/docs")
                        }
                    }}
                >
                    {
                        props.type === 'PLATFORM' ? t('plans.platformButton') : t('plans.apiButton')
                    }
                </PlanCardContainer.Button>
            </PlanCardContainer.Row>

            <PlanCardContainer.Row>
                <PlanCardContainer.SecondaryButton
                    onClick={() => navigate(getPlanTypeUrl(props.type))}
                >
                    {t('plans.changePlan')}
                </PlanCardContainer.SecondaryButton>
            </PlanCardContainer.Row>

            <PlanCardContainer.Row>
                <PlanCardContainer.CancelButton
                    onClick={handleCancelPlanClick}
                    disabled={isCancelingPlan}
                >
                    {
                        isCancelingPlan ? t('plans.cancelingPlan') : t('plans.cancelPlanButton')
                    }
                </PlanCardContainer.CancelButton>
            </PlanCardContainer.Row>
        </PlanCardContainer>
    )
}