import { useState } from "react";
import { Loading } from "../Loading";

interface ImageWithLoadingProps {
    src: string;
}

export function ImageWithLoading(props: ImageWithLoadingProps) {

    const { src } = props;

    const [isLoading, setIsLoading] = useState(true);

    function handleImageLoaded() {
        setIsLoading(false);
    }

    return (
        <>
            {isLoading && (
                <div style={{ display: 'flex', height: '280px', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ height: "35px", width: "35px" }}><Loading /></div>
                </div>
            )}
            <img src={src} onLoad={handleImageLoaded} style={{ display: isLoading ? "none" : "block" }} alt='demonstração da plataforma' />
        </>
    );
}

export default ImageWithLoading;