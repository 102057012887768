import { useNavigate } from "react-router-dom";
import { PostCard } from "./styled";

interface PostProps {
    imageSrc: string;
    title: string;
    subtitle: string;
    link: string;
}

export const Post = (props: PostProps) => {
    const { imageSrc, title, subtitle, link } = props;
    const navigate = useNavigate();
    
    console.log(props)

    return (
        <PostCard>
            <PostCard.Title>{title}</PostCard.Title>

            <PostCard.Content>
                <PostCard.ImageContainer>
                    <PostCard.Image src={imageSrc} alt="Post" />
                </PostCard.ImageContainer>
                <PostCard.Subtitle>{subtitle}</PostCard.Subtitle>
            </PostCard.Content>

            <PostCard.ButtonFlex>
                <PostCard.Button
                    onClick={() => {
                        navigate(`/blog/${link}`)
                    }}
                >
                    Ver
                </PostCard.Button>
            </PostCard.ButtonFlex>
        </PostCard>
    )
}