import styled from "styled-components";

const Main = styled.main`
    display: grid;
    grid-template-columns: 250px 5fr;
    width: 100%;
    background: url("/CropMonitoring-image-1-mob.webp") no-repeat center center fixed;
	background-size: cover;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #07130A 150px, rgba(7, 19, 10, 0) 100%);
        opacity: 1;
        z-index: 1;
        top: -150px;
    }

    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #07130A;
        opacity: .8;
        z-index: 0;
        bottom: 0;
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
    }
`;

const Content = styled.div<{isLoading?: boolean}>`
    padding: 2rem 4rem;
    z-index: 1;

    ${({ isLoading }) => isLoading && `
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    `}

    @media (max-width: 768px) {
        padding: 1rem .5rem;
        min-height: 70vh;
    }
`;

const Title = styled.h1`
    font-family: Archivo, serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #FFFFFF;

`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
`;

const Label = styled.label`
    font-family: Inter, serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
    margin: 1rem 0;
`;

const Field = styled.div`
    width: 500px;
    height: 45px;
    background: rgba(247, 248, 252, 0.4);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: #fff;
    border: none;
    padding-left: .5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 768px) {
        width: 100%;
        padding: 0;
    }

    @media (max-width: 425px) {
        height: 40px;
    }
`;

const SearchInput = styled.input`
    width: 300px;
    height: 30px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    font-family: Archivo, serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.6);
    background: transparent;
    padding-left: .5rem;

    &:focus {
        outline: none;
    }

    @media (max-width: 768px) {
        width: calc(100% - 1rem);
    }
`;

const TableContainer = styled.div`
    margin-top: 1rem;

    @media (max-width: 768px) {
        width: calc(100vw - 1rem);
        overflow-x: scroll;
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 5px;
    background: #14211B;  
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background: #07130A;
    }
`;

const TableHeader = styled.th`
    font-family: Archivo, serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    padding: 1rem;
    text-align: center;
`;

const TableData = styled.td`
    font-family: Archivo, serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    padding: 1rem;
    text-align: center;
`;

export const DashboardContainer = Object.assign(Main, {
    Content,
    Title,
    FormGroup,
    Label,
    Field,
    TableContainer,
    Table,
    TableRow,
    TableHeader,
    TableData,
    SearchInput,
});