import styled from "styled-components";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1rem 0;

    span {
        font-family: Archivo, serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 10px;
        color: #ACACAC;
        margin-top: 1rem;
    }
`;

const Group = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const Title = styled.h2<{ isTotal?: boolean, color?: string }>`
    font-family: Archivo, serif;
    font-weight: 600;
    font-size: ${props => props.isTotal ? '30px' : '14px'};
    line-height: 18px;
    color: ${props => props.color ? props.color : '#0A0D13'};
`;

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background-color: #D9D9D9;
`;

export const CheckoutPrice = Object.assign(Main, {
    Group,
    Title,
    Divider
});