import styled, { keyframes } from "styled-components";

const fadeOut = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const pulse = keyframes`
  0% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`;

const Main = styled.main`
  width: 80%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Card = styled.section`
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Title = styled.h1`
  font-family: Archivo, serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  position: relative;
  width: 100%;
  padding: 1rem 0;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    opacity: .5;
    background-color: #ACACAC;
  }
`;

const Control = styled.div`
  display: block;
  width: 100%;
  margin: 0;
`;

const Group = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  width: 100%;
  margin: 2rem 0 0;
  flex: 1;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  font-family: Archivo, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 10px;

  & > span {
    color: #FF0000;
  }
`;

const Input = styled.input<{ error?: boolean }>`
  height: 40px;
  width: calc(100% - 1rem - 3px);
  border: 1px solid ${props => props.error ? '#FF0000' : '#ACACAC'};
  border-radius: 5px;
  margin: .5rem 0;
  padding-left: 1rem;
  font-family: Poppins, serif;
  transition: all .2s;

  &:focus {
    outline: none;
  } 
`;

const Footer = styled.footer`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  grid-template-areas: "back back back next";
  width: 100%;
`;

const Button = styled.button`
    width: 300px;
    height: 40px;
    background: #F27E30;
    padding: 8px 24px;
    font-family: Archivo, serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFF;
    margin: 1rem auto;
    transition: .2s;
    grid-area: next;
  
    &:disabled {
        background: #F27E30AA;
        cursor: not-allowed;
        animation: ${pulse} 1.5s infinite;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
`;

const SecondaryButton = styled(Button)`
  background: transparent;
  color: #F27E30;

  &:disabled {
    background: transparent;
    color: #F27E30AA;
  }

  &:hover {
    background: #F27E30;
    color: #FFF;
  }
`;

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2px 1fr;
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const Divider = styled.div`
  height: 100%;
  width: 2px;
  background-color: #D9D9D9;
`;

const RadioContainer = styled.label`
    display: grid;
    grid-template-areas: "radio label";
    place-content: start;
    grid-gap: .5rem;
    font-family: Inter, serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    width: auto;
  
    & > input[type="radio"] {
        display: none;
    }
  
    & > input[type="radio"]:checked + div {
      &::before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #F27E30;
        animation: ${fadeOut} .2s;
      }
      
      border: 2px solid #F27E30;
    }
  
    & > :not(input[type="radio"]:checked) + div {
      border: 2px solid #ACACAC;
    }

    
`;

const Radio = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    place-content: center;
`;

const RadioControl = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
`;

// resultado da compra
const Result = styled.div`
  display: grid;
  place-items: center;
  place-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
`;

const ResultTitle = styled.h1`
  font-family: Archivo, serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 12px;
  color: #0A0D13;
`;

const ResultImageContainer = styled.div`
  height: 100px;
  width: 100px;
  margin: 2rem auto;

  & > img {
    height: 100%;
    width: 100%;
    margin: 0 auto;
  }
`;

const ResultText = styled.p`
  font-family: Archivo, serif;
  font-weight: 500;
  font-size: 25px;
  line-height: 28px;
  color: #0A0D13;
`;

const Anchor = styled.a`
  color: #F27E30;
  text-decoration: underline;
  font-family: Archivo, serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  transition: .2s;
  cursor: pointer;

  &:hover {
    opacity: .9;
  }
`;

export const Form = Object.assign(Main, {
    Card,
    Title,
    Control,
    Group,
    Label,
    Input,
    Button,
    Footer,
    Wrapper,
    Divider,
    RadioContainer,
    Radio,
    RadioControl,
    SecondaryButton,
    Result,
    ResultTitle,
    ResultImageContainer,
    ResultText,
    Anchor
})
