import { ApiKeyContainer } from './styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

export const ApiKeyField = ({ value }: { value: string }) => {
    const { t } = useTranslation();
    const [isCopied, setIsCopied] = useState(false);
    const [isHidden, setIsHidden] = useState(true);

    const hideValue = (value: string) => {
        const hiddenValue = value.replace(/./g, '*');

        return hiddenValue;
    }

    const handleCopy = () => {
        window.navigator.clipboard.writeText(value).then(() => {
            setIsCopied(true);

            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        });
    }

    return (
        <ApiKeyContainer>
            <ApiKeyContainer.KeyField>
                {
                    isHidden ? hideValue(value) : value
                }
            </ApiKeyContainer.KeyField>

            <ApiKeyContainer.IconButton
                onClick={() => setIsHidden(!isHidden)}
            >
                {
                    isHidden ? <BsEyeSlash /> : <BsEye />
                }
            </ApiKeyContainer.IconButton>

            <ApiKeyContainer.CopyButton
                onClick={handleCopy}
                isCopied={isCopied}
            >
                {
                    isCopied ? t('api.buttonCopied') : t('api.buttonCopy')
                }
            </ApiKeyContainer.CopyButton>
        </ApiKeyContainer>
    )
}