import styled, { keyframes } from "styled-components";

const fadeLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;


export const PresentationMain = styled.main<{ imageUrl?: string }>`
	display: flex;
	flex-direction: row;
    background: url(${props => props.imageUrl ? props.imageUrl : '#0A1E29'}) no-repeat center;
	background-size: cover;
	min-height: 100vh;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background: #0A1E29;
		opacity: .6;
		top: 0;
		z-index: 0;
	}

	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 80%;
		background: linear-gradient(180deg, #0A1E29 30.73%, rgba(7, 19, 10, 0) 100%);
		transform: rotate(180deg);
		bottom: -10px;
	}

  @media (max-width: 768px) {
    height: auto;
  }
`;

export const Presentation = styled.div`
	width: 50%;
	padding: 4rem;
	margin-top: 10rem;
	color: #FFF;
	z-index: 1;

  h2 {
    font-family: Archivo, serif;
    font-weight: 500;
    font-size: 80px;
    line-height: 80px;
    color: #FFF;
    margin-bottom: 1rem;

    span {
      font-family: Archivo, serif;
      color: #61C67A;
    }
  }

  p {
      font-family: Archivo, serif;
      font-weight: 200;
      font-size: 20px;
      line-height: 24px;

      span {
        font-family: Archivo, serif;
        color: #61C67A; 
      }
  }

  @media (max-width: 768px) {
    padding: 1rem;
    
    h2 {
      font-size: 60px;
      line-height: 70px;
    }
  }

  @media (max-width: 499px) {
    width: 100%;
    padding: 1rem;
    
    h2 {
      font-size: 50px;
      line-height: 60px;
    }

    p {
      font-size: 16px;
    }
  }
`;

export const PlansSection = styled.section`
  width: 100%;
  background: #0A1E29;
  color: #FFF;
  padding: 2rem 2rem 1rem;
  display: grid;
  place-items: center;

  & > div {
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const PlanList = styled.div`
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4rem;
`;

export const Title = styled.h1`
    font-family: Archivo, serif;
    font-weight: 500;
    font-size: 40px;
    line-height: 45px;
    width: 100%;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 35px;
    }
`;

export const Input = styled.input`
  width: calc(400px - 1rem);
	height: 40px;
	border: 2px solid #F27E30;
	border-radius: 5px;
	padding-left: 1rem;
	background: transparent;
	color: #FFF;
	margin: 1rem auto;

	&::placeholder {
		font-family: Archivo, serif;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: rgba(255, 255, 255, 0.4);
	}

	&:focus {
		outline: none;
	}

  @media (max-width: 500px) {
    width: calc(300px - 1rem);
  }

  @media (max-width: 400px) {
    width: calc(100% - 1rem);
  }
`;

export const Button = styled.button`
  width: 400px;
	height: 40px;
	background: #F27E30;
	padding: 8px 24px;
	font-family: Poppins, serif;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #FFF;
	margin: 1rem auto;
  transition: .2s;
  
  &:disabled {
      background: #F27E30AA;
      cursor: not-allowed;
      display: grid;
      place-items: center;
      place-content: center;
      color: #BAC4C8;
  }

  @media (max-width: 500px) {
    width: 300px;
  }

  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const PlanCheckout = styled.section`
    height: 70vh;
    width: 100%;

    @media (max-width: 768px) {
      height: auto;
    }
`;

export const PlanCheckoutContainer = styled.div`
    height: 100%;
    width: 100%;
    background: url("/CropMonitoring-image-1-mob.webp") no-repeat center;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 0;
  
    & > div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        @media (max-width: 768px) {
          flex-direction: column;
        }
    }
  
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #0A1E29;
        opacity: .8;
        top: 0;
        z-index: 1;
    }
`;

export const PlanCheckoutCard = styled.div`
  width: 400px;
  height: 500px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: #FFF;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: relative;
  
  & > h3 {
    font-family: Archivo, serif;
    font-weight: 500;
    font-size: 40px;
    line-height: 45px;
    animation: ${fadeLeft} 1.5s ease-in-out;
  }
  
  & > p {
    font-family: Archivo, serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin: .5rem 0;
    animation: ${fadeLeft} 1.5s ease-in-out;
    
    & > span {
        font-family: Archivo, serif;
        font-weight: 600;
        color: #F27E30;
    }
  }

  @media (max-width: 768px) {
    width: 90%;
    margin: 1rem 0;
  }
`;

export const PlanCheckoutBadge = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: #F27E30;
    padding: .5rem 1rem;
    border-radius: 0 0 0 5px;
    font-family: Archivo, serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFF;
`;

export const PlanCheckoutFooter = styled.footer`
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    place-content: end;
    width: 100%;
    padding: 1rem 2rem;
    background: #0A1E29;

    @media (max-width: 768px) {
      place-content: center;
    }
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    color: #FFF;
`;

export const ModalLinks = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
`;

export const ModalLink = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    color: #FFF;
    text-decoration: none;
    margin: .5rem 0;

    svg {
        padding-right: .5rem;
    }

    &:hover {
        p, svg {
            opacity: .8;
        }
    }
`;

export const Badge = styled.section`
  background: #f27e30eb;
  border-radius: 12px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #FFF;
  font-family: Poppins, serif;
  font-weight: 600;
  font-size: 1rem;
  padding: .2rem .5rem;
  line-height: 1.2rem;
  text-align: left;
  margin: 25px 0;
  width: 150px;

  & > span {
    font-size: .9rem;
  }
`;