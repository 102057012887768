import { Navigate } from "react-router-dom"

interface ProtectedRouteProps {
  children: JSX.Element
}

export default function ProtectedRoute({children}: ProtectedRouteProps){
  const isLoggedIn: string | null = localStorage.getItem('@BiosatToken');

  if(!isLoggedIn){
    return <Navigate to={`/login?redirect=${window.location.pathname}`} />
  }

  return children;
}
