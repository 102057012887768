import styled, { keyframes } from "styled-components";

export const Container = styled.div`
    width: 100%;
    background: linear-gradient(180.03deg, #032335 0%, #041E2C 100%);
    overflow-x: hidden;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-template-areas: "left right";
    width: 100%;

    @media (max-width: 800px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-template-areas: "top" "bottom";
    }
`;
export const Title = styled.h2`
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    font-family: Archivo, serif;
    font-weight: 500;
    font-size: 40px;
    color: #fff;
    text-align: center;
    display: grid;
    place-items: center;
    place-content: center;
    grid-gap: 0;
    grid-template-columns: 1fr 1fr 1fr;

    & > div {
        background: #61C67A;
        width: 100%;
        height: 2px;
    }

    @media (max-width: 800px) {
        text-align: left;
        font-size: 32px;
        padding-left: 2rem;
        grid-template-columns: 1fr 1fr;

        & > div:first-child {
            display: none;
        }
    }

    @media (max-width: 500px) {
        font-size: 20px;
    }
`;

export const Stack = styled.div<{
    justifyContent?: string;
    alignItems?: string;
    direction?: string;
    other?: string;
}>`
    display: flex;
    flex-direction: ${props => props.direction || "column"};
    justify-content: ${props => props.justifyContent || "center"};
    align-items: ${props => props.alignItems || "center"};
    width: 100%;
    ${props => props.other || ""}
`;

const PresentationContainer = styled.main`
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    place-items: center;
    grid-template-areas: "left right";

    @media (max-width: 800px) {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
`;

const PresentationText = styled.aside`
    width: 400px;
    position: relative;
    margin-top: 50px;

    &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 2px;
        background: #61C67A;
        left: -20px;
        top: 0;
        transform: translateX(-50%);

        @media (max-width: 800px) {
            height: 80px;
            left: 50px;
        }

        @media (max-width: 500px) {
            left: 25px;
        }
    }

    @media(max-width: 800px) {
        text-align: center;
        margin-top: 15px;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`;

const PresentationTitle = styled.h1`
    font-family: Raleway, serif;
    font-size: 60px;
    line-height: 70px;
    font-weight: 100;
    color: #FFF;
    margin-bottom: 20px;

    @media (max-width: 800px) {
        font-size: 40px;
        line-height: 40px;
    }
`;

const PresentationDescription = styled.p`
    font-family: Raleway, serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #FFF;

    span {
      color: #61C67A
    }

    @media(max-width: 800px) {
        font-size: 16px;
        line-height: 20px;
    }

    @media (max-width: 500px) {
        width: 100%;
        padding: 0 1rem;
    }
`;

const PresentationImages = styled.div`
    object-fit: cover;
    grid-area: right;
    background: url("/presentation-image.webp") no-repeat center;
    background-size: cover;
    position: relative;
    height: 650px;
    width: 100%;
    margin-top: 80px;
    

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background: url("/presentation-image-bg.webp") no-repeat center;
        background-size: cover;
        opacity: .5;
        right: 25%;

        @media (max-width: 800px) {
            display: none;
        }
    }

    @media (max-width: 500px) {
        height: 300px;
    }

    @media (max-width: 800px) {
        height: 400px;
    }
`;

export const Presentation = Object.assign(PresentationContainer, {
    Text: PresentationText,
    Title: PresentationTitle,
    Description: PresentationDescription,
    Images: PresentationImages
});


const SoluctionsContainer = styled(Stack)`
    height: 600px;
    padding: 0 2rem;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("/abstract-background-with-low-poly-design 1.webp") no-repeat;
        background-size: cover;
        opacity: .5;
        z-index: 0;
    }

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 300px;
        background: linear-gradient(180.03deg, #032335 0%, transparent 100%);
        z-index: 1;
        top: 0;
    }

    @media (max-width: 800px) {
        padding: 0;
    }
`;

const SoluctionContainer = styled.section`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(1, 1fr);
    place-items: center;
    gap: 2rem;
    overflow-x: scroll;
    cursor: grab;
    z-index: 1;

    &::-webkit-scrollbar {
        height: 8px;
        background-color: #07130A;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        width: 8px;
        background-color: #61C67A;
        border-radius: 10px;
    }
`;

const SoluctionItem = styled(Stack)`
    width: 300px;
    height: 300px;
    scroll-snap-align: start;

    img {
        width: 50px;
        height: 60px;
    }

    @media (max-width: 800px) {
        width: 300px;
        padding: 0 1rem;
    }
`;

const SoluctionItemDescription = styled(Stack)`
  margin-top: 20px;
  background: #041E2C;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  padding: 12px 24px;
  z-index: 1;

  h3 {
    font-family: Archivo, serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #fff;
  }

  p {
    height: 0;
    font-family: Archivo, serif;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    line-height: 20px;
    overflow: hidden;
    transition: all .8s;

    span {
      color: #61C67A
    }
  }

  &:hover {
    p {
      height: 140px;
      margin-top: 10px;
    }
  }
`;

export const Soluctions = Object.assign(SoluctionsContainer, {
    Soluction: SoluctionContainer,
    Title,
    Item: SoluctionItem,
    ItemDescription: SoluctionItemDescription
});

export const HowWorksSection = styled(Stack)`
    background: url("/fundo-segunda-sessão.webp") no-repeat;
    padding: 2rem 0;
    position: relative;
    height: fit-content;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 400px;
        background: linear-gradient(180deg, transparent 0%, #051F2D 50%, rgba(6, 30, 42, 0) 100%);
        top: -200px;
        left: 0;
        z-index: 0;
    }

    @media (max-width: 800px) {
        background-size: cover;
    }
`;

export const HowWorksHeader = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    text-align: center;
    position: relative;
    z-index: 1;
  
    @media (max-width: 800px) {
        justify-content: flex-start;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scroll-padding-left: 20px;
        padding-bottom: .5rem;
        -ms-overflow-style: none;
    }
`;

export const HowWorksItem = styled.li`
    font-family: Archivo, serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    border-left: 2px solid #00D3DE66;
    border-right: 2px solid #00D3DE66;
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
  
    &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        background: #00D3DE66;
        bottom: 0;
        left: 0;
        transition: all .6s;
      
        @media (max-width: 800px) {
          background: #F27E30;
        }
    }
  
    &:hover {
        &:before {
            width: 100%;
        }
    }
  
    @media (max-width: 800px) {
      border-style: none;
    }
`;

export const HowWorksTitle = styled.h3<{ selected: boolean }>`
    height: 100%;
    cursor: pointer;
    transition: all .3s;
    color: ${({ selected }) => selected ? '#F27E30' : '#FFF'};
    display: flex;  
    align-items: center;
    justify-content: center;

  @media (max-width: 800px) {
    font-size: 16px;
  }
`;

export const HowWorksContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "left right";
    place-items: end;
    place-content: center;
    margin-top: 2rem;
    width: 100%;

    @media (max-width: 800px) {
        padding: 2rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const HowWorksContentLeft = styled.div`
    grid-area: left;
    height: 350px;
    width: 600px;
    margin-top: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 800px) {
        margin-top: 0;
        margin-bottom: 1rem;
        width: 100%;
        height: auto;
    }

    img {
        height: 100%;
        width: 100%;
    }
`;

export const List = styled(Stack)`
    height: 100%;
    position: relative;
    color: #fff;
    transition: all .3s;
  
    @media (max-width: 800px) {
        padding: 0 0 0 .8rem;
        width: 100%;
    }
`;

export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  margin-bottom: 20px;
  font-family: Archivo, serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  img {
    padding-right: 20px;
    
    @media (max-width: 800px) {
      padding-right: .5rem;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const ServicesSection = styled.div`
    padding: 6rem 0;
`;

export const ServicesContainer = styled(Stack)`
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    width: 100%;
    padding: 2rem 1rem;
    place-content: start;
    place-items: start;
  
    @media (max-width: 800px) {
        display: flex;
        flex-direction: column-reverse;
        padding: 0;
        & > img {
            margin: 2rem 0;
            width: 100%;
            padding: 0 1rem;
        }
    }
`;

export const ServicePictureContainer = styled.div<{ isApi: boolean }>`
    width: 100%;

    ${({ isApi }) => isApi && `
        position: relative;

        &:before {
            content: "";
            position: absolute;
            background: url('/computador.webp') no-repeat center;
            background-size: cover;
            bottom: 0;
            right: -50px;
            width: 400px;
            height: 400px;
            display: grid;
            place-items: end;
            place-content: end;
            
            & > img {
                width: 100%;
                height: 100%;
            }

            @media (max-width: 1000px) {
                width: 300px;
                height: 300px;
            }

            @media (max-width: 800px) {
                width: 200px;
                height: 200px;
                right: 0;
            }
        }
    `}

    & > img {
        width: 100%;
        ${({ isApi }) => !isApi && `
            margin-left: -100px;

            @media (max-width: 800px) {
                margin-left: 0;
            }
        `}
    }
    
    @media (max-width: 800px) {
        margin: 2rem 0;
        width: 100%;
        padding: 0 1rem;
    }
`;

export const ServiceMobileHeader = styled(Stack) <{ selected: number }>`
    display: none;
    width: 100%;
    margin-top: 2rem;
  
    & > h2:first-child {
      font-weight: ${({ selected }) => selected === 1 ? '600' : '200'};
      color: ${({ selected }) => selected === 1 ? '#00ED9E' : '#fff'};
      
      &:before {
        width: ${({ selected }) => selected === 1 ? '100%' : '0'};
      }
    }
  
    & > h2:last-child {
        font-weight: ${({ selected }) => selected === 0 ? '600' : '200'};
        color: ${({ selected }) => selected === 0 ? '#00ED9E' : '#fff'};
      
        &:before {
            width: ${({ selected }) => selected === 0 ? '100%' : '0'};
        }
    }
  
    & > h2 {
      position: relative;
      transition: all .3s;
      
      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        background: #00ED9E;
        bottom: -10px;
        left: 0;
        transition: all .6s;
      }
    }
  
    @media (max-width: 800px) {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
`;

export const ServicesTitle = styled.h2`
    width: 100%;
    text-align: start;
    font-family: Archivo, serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #00ED9E;
    margin-bottom: 2rem;
    
    @media (max-width: 800px) {
      display: none;
    }
`;

export const Button = styled.button`
    width: 100%;
    height: 50px;
    background: #00ED9E;
    border-radius: 4px;
    border: none;
    font-family: Archivo, serif;
    font-weight: 700;
    font-size: 14px;
    color: #FFF;
    margin-top: 10px;
    cursor: pointer;
    transition: all .3s;
  
    &:hover {
        opacity: .8;
    }
`;

export const SecondaryButton = styled(Button)`
    background: transparent;
    border: 1px solid #00ED9E;
    color: #00ED9E;
`;

export const PartnersSection = styled.section`
    display: grid;
    grid-auto-flow: column;
    place-content: center;
    place-items: center;
    grid-gap: 2rem;

    width: 100%;
    padding: 4rem 0;

    & > img {
        height: 200px;
    }
  
    & > div {
        width: 400px;
        height: 150px;
        background: #FFF;
        margin: 2rem 1rem;
        
        @media (max-width: 800px) {
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
    }

    @media (max-width: 750px) {
        grid-auto-flow: row;
    }
`;
