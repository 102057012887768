export function getMaskForCpfCnpj(input: string): string {

    if (input.length <= 11) {
        // Format CPF
        let formattedInput = "";
        for (let i = 0; i < input.length; i++) {
            if (i === 3 || i === 6) {
                formattedInput += ".";
            }
            if (i === 9) {
                formattedInput += "-";
            }
            formattedInput += input[i];
        }
        return formattedInput;
    } else {
        // Format CNPJ
        let formattedInput = "";
        for (let i = 0; i < input.length; i++) {
            if (i === 2 || i === 5) {
                formattedInput += ".";
            }
            if (i === 8) {
                formattedInput += "/";
            }
            if (i === 12) {
                formattedInput += "-";
            }
            formattedInput += input[i];
        }
        return formattedInput;
    }
}