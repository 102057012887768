export const PT = {
    pt: {
        translation: {
            'navbarSolution': 'Soluções',
            'navbarHowWorks': 'Como funciona',
            'navbarServices': 'Serviços',
            'navbarPartners': 'Empresas parceiras',
            'navbarContact': 'Contato',
            'navbarDropdown': 'Acesse',
            'navbarDropdownDashboard': 'Área do cliente',
            'navbarPlatform': 'Plataforma',
            'navbarAPI': 'Documentação API',
            'presentationDescriptionSpan': 'Intensifique',
            'presentationDescription': 'seus resultados com',
            'presentationTitle': 'Agricultura de precisão',
            'presentationDescriptionMain1': 'A ',
            'presentationDescriptionMain2': 'Biosat ',
            'presentationDescriptionMain3': 'é uma plataforma que visa auxiliar os produtores e consultores agrícolas, a produzir com maior',
            'presentationDescriptionMain4': ' sustentabilidade, ',
            'presentationDescriptionMain5': 'buscando o aumento da produtividade, do retorno econômico e diminuição dos impactos ambientais.',
            'solutionsTitle': 'Soluções',
            'solution1Title': 'Modo amostragem de solo',
            'solution1Text1': 'A partir da amostra de solo georreferenciada é possível',
            'solution1Text2': ' gerenciar ',
            'solution1Text3': 'os dados com maior',
            'solution1Text4': 'praticidade',
            'solution1Text5': ', além de facilitar a geração de relatórios e consulta de dados.',
            'solution2Title': 'Mapa de produtividade',
            'solution2Text1': 'A partir dos',
            'solution2Text2': ' satélites mais modernos ',
            'solution2Text3': 'do mercado identifique manchas produtivas de maneira fácil e eficiente, ',
            'solution2Text4': 'receba ',
            'solution2Text5': 'mapas para acompanhar a produtividade e analise o histórico de desenvolvimento da sua lavoura.',
            'solution3Title': 'Múltiplos acessos',
            'solution3Text': 'Cadastre diversas propriedades para armazenar e visualizar os dados das suas safras em uma única conta, além disto, compartilhe o acesso com quantos colaboradores desejar de maneira prática e individualizada.',
            'solution4Title': 'Função Siga-me',
            'solution4Text1': 'Identificou áreas com o desenvolvimento diferenciado? A função',
            'solution4Text2': ' Siga-me ',
            'solution4Text3': 'utiliza sua localização via GPS, para delimitar o melhor trajeto pelo talhão até o ponto de interesse.',
            'solution5Title': 'Monitoramento Climático',
            'solution5Text': 'Desenvolva um planejamento estratégico para suas atividades agrícolas futuras com o acompanhamento das condições climáticas em tempo real.',
            'howWorksTitle': 'Como funciona',
            'howWorksHeaderTitle1': 'Cadastro',
            'howWorksHeaderTitle2': 'Captura de dados',
            'howWorksHeaderTitle3': 'Monitoramento',
            'howWorksHeaderTitle4': 'Análise',
            'howWorksInfo': {
                'Cadastro': {
                    funtionality: {
                        '0': 'Escolha o plano que mais se encaixa para você',
                        '1': 'Cadastre-se no nosso site',
                        '2': 'Delimite seu talhão através do nosso mapa interativo',
                        '3': 'Registre as culturas, datas de plantio e colheita de suas safras',
                        '4': 'Pronto! Você já pode começar a usar o nosso serviço',
                    }
                },
                'Captura de dados': {
                    funtionality: {
                        '0': 'O nosso aplicativo integra os melhores satélites do mercado, use para registrar dados da sua lavoura',
                        '1': 'Importe os resultados das amostras do solo georreferenciadas com maior facilidade',
                        '2': 'Os dados serão analisados pelo nosso servidor',
                        '3': 'Receba mapas de NDVI para acompanhar o desenvolvimento e umidade do solo da sua lavoura',
                        '4': 'Receba imagens da sua lavoura em tempo real',
                    }
                },
                Monitoramento: {
                    funtionality: {
                        '0': "Monitore as condições do seu plantio em tempo real",
                        '1': "Monitore as mudanças no clima em cada talhão",
                        '2': "Verifique de modo detalhado a quantidade de nuvens e eventos climáticos em tempo real",
                        '3': "Visualize histórico da temperatura, precipitação e pluviometria por talhão"
                    }
                },
                'Análise': {
                    funtionality: {
                        '0': "Analise o desenvolvimento da sua lavoura, através dos dados obtidos pelo nosso sistema e compare-as com datas anteriores",
                        '1': "Obtenha mapas para um diagnóstico preciso da sua lavoura, a partir das análises de solo georreferenciadas",
                        '2': "Consulte os mapas de fertilidade e de aplicação por taxa variável para um diagnóstico preciso da sua lavoura",
                        '3': "Receba gráficos, visualize os resultados com maior praticidade e compare-os com safras anteriores",
                        '4': "Determine as soluções de maneira assertiva"
                    }
                }
            },
            'servicesTitle': 'Serviços',
            'servicesTitle1': 'Plataforma Biosat',
            'servicesTitle2': 'Safra API',
            'servicesInfo': {
                '0': {
                    '0': "Permite o monitoramento do cultivo em tempo real",
                    '1': "Através de análises de solo georreferenciadas, obtenha mapas de aplicação por taxa variável",
                    '2': "Utilize mapas para uma aplicação de insumos com maior precisão",
                    '3': "Reduza os custos de aquisição e aplicação dos insumos",
                    '4': "Obtenha maior aproveitamento e eficiência no uso de nutrientes",
                    '5': "Utilize as informações para solucionar desafios"
                },
                '1': {
                    '0': "Histórico de índices vegetativos e condições climáticas",
                    '1': "Previsão climática de 15 dias por ponto",
                    '2': "Dados georreferenciados processados especialmente para uso agropecuário",
                    '3': "Ferramentas de geoprocessamento que possibilitam o calculo da área, distância e transformação de coordenadas com praticidade",
                    '4': "Fácil integração com qualquer sistema",
                    '5': "Suporte técnico disponível"
                }
            },
            'subscriptionButton': 'Planos',
            'docsButton': 'Ver documentação',
            'partnersTitle': 'Empresas parceiras',
            'copyRight': '© 2023 Biosat. Todos os direitos reservados.',
            'footerPhone': 'Telefone',
            'footerLinks': {
                '0': 'Plataforma Biosat',
                '1': 'Planos Plataforma Biosat',
                '2': 'Planos Safra API',
                '3': 'Documentação Safra API',
            },
            'authPassword': 'Senha',
            'authButton': 'Acessar',
            'forgotPassword': 'Esqueceu sua senha?',
            'authError': 'Usuário ou senha incorretos',
            'authErrorEmail': 'Email inválido',
            'signupText': 'Não tem uma conta?',
            'signupButton': 'Cadastre-se',
            'forgotPasswordTitle': 'Esqueci Minha Senha',
            'forgotPasswordText': 'Digite seu email para receber um link de recuperação de senha',
            'forgotPasswordButton': 'Enviar',
            'forgotPasswordBack': 'Voltar',
            'successSendEmail': 'Um email foi enviado para você',
            'emailNotFound': 'Email não encontrado',
            'errorSendEmail': 'Erro ao enviar email',
            'resetPasswordLoading': 'Carregando...',
            'resetPasswordError': 'Link inválido',
            'resetPasswordTitle': 'Mudar Senha',
            'resetPasswordLabel': 'Senha',
            'resetPasswordInput': 'Nova Senha',
            'confirmPasswordLabel': 'Confirmar Senha',
            'confirmPasswordInput': 'Confirme sua senha',
            'resetPasswordButton': 'Confirmar',
            'signupTitle': 'Crie uma conta',
            'signupName': 'Nome Completo',
            'signupEmail': 'Endereço de e-mail',
            'signupCpfCnpj': 'CPF ou CNPJ',
            'signupPassword': 'Criar uma senha',
            'signupConfirmPassword': 'Repetir senha',
            'signupButton2': 'Criar Conta',
            'signupLogin': 'Já tem uma conta? Faça login',
            'termsText1': 'Aceito o ',
            'termsText2': 'Contrato de termos e serviço ',
            'termsText3': 'que inclui a sua ',
            'termsText4': 'Declaração de Privacidade',
            'emailTerms': 'Quero receber e-mails sobre produtos/serviços biosat, atualizações de funcionalidades, recursos, parceiros e ofertas especiais.',
            'platformPlans': {
                '0': {
                    'title': 'Plano Básico',
                    'benefits': {
                        '0': "Histórico de 4 anos de imagens de satélite disponível",
                        '1': "Cadastre propriedades, talhões e safras",
                        '2': "Imagens de NDVI, Índice de Umidade e Cor Natural",
                        '3': "Função Siga-me",
                    }
                },
                '1': {
                    'title': "Plano Intermediário",
                    'benefits': {
                        '0': "Histórico de 4 anos de imagens de satélite disponível",
                        '1': "Cadastre propriedades, talhões e safras",
                        '2': "Imagens de NDVI, Índice de Umidade e Cor Natural",
                        '3': "Função Siga-me",
                        '4': "Relatório virtual com análise quantitativa dos índices vegetativos e comparativo entre talhões e safras",
                        '5': "Histórico climático e previsão estendida de 15 dias específica por talhão"
                    },
                },
                '2': {
                    'title': "Plano Avançado",
                    'benefits': {
                        '0': "Histórico de 4 anos de imagens de satélite disponível",
                        '1': "Cadastre propriedades, talhões e safras",
                        '2': "Imagens de NDVI, Índice de Umidade e Cor Natural",
                        '3': "Função Siga-me",
                        '4': "Relatório virtual com análise quantitativa dos índices vegetativos e comparativo entre talhões e safras",
                        '5': "Histórico climático e previsão estendida de 15 dias específica por talhão",
                        '6': "Função amostragem de solo para coletar e interpretar análises de solo de maneira georreferenciada",
                        '7': "Mapas de recomendação com base na cultura, condições do solo e resultado das análises laboratoriais"
                    },
                }
            },
            'platformPlanPrice': 'A partir de ',
            'platformPlanPrice2': ' por mês',
            'platformTitle1': 'Plataforma ',
            'platformTitle2': 'Biosat',
            'platformDescription1': 'O sensoriamento remoto é uma tecnologia que promove a obtenção de imagens e dados da superfície terrestre. Essa tecnologia propiciou o desenvolvimento da ',
            'platformDescription2': 'Agricultura de precisão. ',
            'platformDescription3': 'A BIOSAT utiliza imagens obtidas através do monitoramento de satélites a fim de fornecer uma caracterização espectral completa de uma área, permitindo sua visualização de forma digital. A plataforma BIOSAT torna possível uma visão totalmente ampla da sua propriedade, disponibilizando imagens de alta resolução, facilitando a coleta de dados precisos e oferecendo facilidade na detecção de mudança no uso da terra.',
            'planTitle': 'Conheça nossos planos',
            'benefitsTitle': 'Benefícios',
            'whatsAppButton': 'Solicite pelo WhatsApp e garanta seu primeiro mês grátis!',
            'hectareInput': 'ESCREVA AQUI A ÁREA EM HECTARES',
            'quoteButton': 'FAZER COTAÇÃO',
            'selectPlan': 'Selecione um plano',
            'price': 'Preço',
            'modalTitle': 'Nós temos uma proposta para você!',
            'modalDescription': 'Entre em contato com a nossa equipe de vendas para preços especiais!',
            'apiDescription': 'API (Application Programming Interface) é uma ferramenta que permite a transmissão de dados entre vários softwares através de linguagem de código de programação. Através da nossa API, você pode implementar de maneira descomplicada imagens de satélite, índices de vegetação e dados meteorológicos às analises de desenvolvimento da produção.',
            'apiPlans': {
                '0': {
                    'title': 'Plano Básico',
                    'benefits': {
                        '0': "40+ anos de histórico das condições climáticas locais",
                        '1': "5+ anos de histórico para mais de 10 índices vegetativos como NDVI, SAVI, EVI ",
                        '2': "Monitoramento de nuvens carregadas",
                        '3': "Índice de cobertura de nuvens para imagens de Sentinel-2 com mais de 90% acurácia",
                        '4': "Funções de geoprocessamento como cálculo de área e transformação de coordenadas geográficas",
                        '5': "10.000 Unidades de Processamento (UC) por mês",
                        '6': "100 Unidades de Processamento (UC) por minuto"
                    }
                },
                '1': {
                    'title': "Plano Intermediário",
                    'benefits': {
                        '0': "40+ anos de histórico das condições climáticas locais",
                        '1': "5+ anos de histórico para mais de 10 índices vegetativos como NDVI, SAVI, EVI ",
                        '2': "Índice de cobertura de nuvens para imagens de Sentinel-2 com mais de 90% acurácia",
                        '3': "Funções de geoprocessamento como cálculo de área e transformação de coordenadas geográficas",
                        '4': "50.000 Unidades de Processamento (UC) por mês",
                        '5': "500 Unidades de Processamento (UC) por minuto"
                    }
                },
                '2': {
                    'title': "Plano Avançado",
                    'benefits': {
                        '0': "40+ anos de histórico das condições climáticas locais",
                        '1': "5+ anos de histórico para mais de 10 índices vegetativos como NDVI, SAVI, EVI ",
                        '2': "Índice de cobertura de nuvens para imagens de Sentinel-2 com mais de 90% acurácia",
                        '3': "Funções de geoprocessamento como cálculo de área e transformação de coordenadas geográficas",
                        '4': "250.000 Unidades de Processamento (UC) por mês",
                        '5': "1000 Unidades de Processamento (UC) por minuto"
                    }
                }
            },
            'subscribe': 'ASSINAR PLANO',
            'invalidCard': 'Número de cartão inválido',
            'invalidExpiration': 'Data de expiração inválida',
            'invalidCVC': 'CVV inválido',
            'invalidName': 'Nome inválido',
            'incompleteName': 'Digite seu nome completo',
            'checkEmailMessage': "Cheque seu email para criar sua senha de acesso a plataforma!",
            'paymentTitle': 'Pagamento',
            'cardLabel': 'Dados do Cartão',
            'cardNumber': 'Número do cartão',
            'cardOwner': 'Nome do titular',
            'cardExpiration': 'Data de expiração',
            'cardCVC': 'CVV',
            'cardOwnerLabel': 'Dados do titular',
            'cardOwnerName': 'Nome completo',
            'cardOwnerEmail': 'Email',
            'cardOwnerPhone': 'Telefone (DDD + Número)',
            'cardOwnerCPF': 'CPF',
            'cardOwnerAddress': 'Logradouro',
            'cardOwnerCEP': 'CEP',
            'cardOwnerNumber': 'Número',
            'cardOwnerComplement': 'Complemento',
            'paymentButton': 'Finalizar Compra',
            'paymentProcessing': 'Efetuando pagamento...',
            'order': 'Resumo do pedido',
            'dashboardTabs': {
                '0': 'Suas informações',
                '1': 'Histórico de pagamentos',
                '2': 'Chave de API',
                '3': 'Seus planos',
                '4': 'Suas informações'
            },
            'profile': {
                'label': 'Informações da conta',
                'nameLabel': 'Nome',
                'emailLabel': 'Endereço de email',
                'cpfCnpjLabel': 'CPF/CNPJ',
            },
            'paymentHistory': {
                'paymentStatus': {
                    'confirmed': 'Confirmado',
                    'pending': "Aguardando pagamento",
                    'overdue': "Pagamento atrasado",
                }
            },
            'dashboardInput': 'Buscar Pedidos',
            'dashboardTable': {
                '0': 'Data do Pedido',
                '1': 'Valor',
                '2': 'Status',
                '3': 'Data de vencimento',
                '4': 'Data do pagamento',
                '5': 'Tipo de pagamento',
                '6': 'Cartão com final ',
                '7': 'Nenhum pagamento encontrado',
            },
            'api': {
                'label': 'Chaves da API',
                'buttonCopy': 'Copiar',
                'buttonCopied': 'Copiado',
            },
            'plans': {
                'label': 'Meus planos',
                'cancelPlan': 'Erro ao cancelar plano!',
                'nextBilling': 'Próxima cobrança em',
                'platformButton': 'Acessar plataforma',
                'apiButton': 'Ver Documentação',
                'changePlan': 'Mudar Plano',
                'cancelingPlan': 'Cancelando...',
                'cancelPlanButton': 'Cancelar Plano',
            },
            'sidebar': {
                '0': 'Perfil',
                '1': 'Pagamentos',
                '2': 'Chave API',
                '3': 'Meus Planos',
                '4': 'Desconectar',
            },
            'successPaymentTitle': 'Agradecemos sua compra',
            'successPaymentMessage': 'Seu pagamento foi processado com sucesso',
            'successPaymentButton': 'Ver sua compra',
            'errorPaymentTitle': 'Seu pagamento não deu certo',
            'errorPaymentMessage': 'Ocorreu um erro ao processar seu pagamento, tente novamente mais tarde.',
            'errorPaymentButton': 'Tentar novamente',
            'planButton': 'Selecionar Plano',
            'planButton2': 'Plano selecionado',
            'firstMonthFree': '1º mês grátis',
        }
    }
}