export function getPhoneMask(input: string) {
    let phone = input.replace(/[\(\)\-\.\s\/\\]/g, '');

    if (phone.length < 11) {
        phone = phone.replace(/^(\d{2})(\d)/g, '($1) $2');
    } else {
        phone = phone.replace(/^(\d{2})(\d{5})(\d)/g, '($1) $2-$3');
    }

    return phone;
}