import { DropdownContainer } from "./styled";
import { AiOutlineArrowDown } from 'react-icons/ai'
import { useState } from "react";

interface link {
    text: string,
    onClick?: () => void,
    openInNewTab?: boolean
}

interface DropdownProps {
    children: React.ReactNode,
    links: link[]
}

export const Dropdown = (props: DropdownProps) => {
    const { children, links } = props

    const [toggleDropdown, setToggleDropdown] = useState(false);

    return (
        <DropdownContainer>
            <DropdownContainer.Placeholder
                toggleDropdown={toggleDropdown}
                onClick={() => setToggleDropdown(!toggleDropdown)}
            >
                {children} <AiOutlineArrowDown style={{ margin: '0 1rem' }} />
            </DropdownContainer.Placeholder>

            <DropdownContainer.Content
                toggleDropdown={toggleDropdown}
            >
                {links.map((link, index) => (
                    <DropdownContainer.Item
                        key={index}
                        onClick={link.onClick}
                    >
                        {link.text}
                    </DropdownContainer.Item>
                ))}
            </DropdownContainer.Content>
        </DropdownContainer>
    )
}