import { useEffect, useRef } from "react";
import { PostComplete as PostCompleteStyle } from "./styled";

interface PostProps {
    imageSrc: string;
    title: string;
    subtitle: string;
    content?: string;
}

export const PostComplete = (props: PostProps) => {
    const { imageSrc, title, content } = props;

    const contentRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        if (contentRef.current !== null) {
            contentRef.current.innerHTML = content || '';
        }
    }, [content])

    return (
        <PostCompleteStyle>
            <img src={imageSrc} alt="Post" />

            <h1>{title}</h1>

            <div ref={contentRef}></div>

            <PostCompleteStyle.MeetButton href="/">
                Conheça nossos produtos
            </PostCompleteStyle.MeetButton>
        </PostCompleteStyle>
    )
}