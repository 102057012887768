import { useState, useEffect } from "react";
import { api } from "../../services/requests";
import { Navbar } from "../Navbar";
import { CheckoutPrice } from "./CheckoutPrice";
import { Form } from './styled';
import SuccessImage from '../../assets/akar-icons_circle-check.svg';
import ErrorImage from '../../assets/bx_error-circle.svg';
import { useNavigate } from "react-router-dom";
import { getCardMask } from "../../util/mask/cardMask";
import { validateCreditCard } from "../../util/validator/validateCreditCard";
import { validateName } from "../../util/validator/validateName";
import { validadeExpirationDate } from "../../util/validator/validateExpirationDate";
import { getExpirationDateMask } from "../../util/mask/expirationDateMask";
import { validateEmail } from "../../util/validator/validateEmail";
import { getPhoneMask } from "../../util/mask/phoneMask";
import { validatePhone } from "../../util/validator/validatePhone";
import { validateCpfCnpj } from "../../util/validator/validateCpfCnpj";
import { getMaskForCpfCnpj } from "../../util/mask/cpfCnpjMask";
import { validatePostalCode } from "../../util/validator/validatePostalCode";
import { getPostalCodeMask } from "../../util/mask/postalCodeMask";
import { toast, ToastContainer } from "react-toastify";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useAuth } from "../../hooks/useAuth";
import { Modal } from "../Modal";
import { Loading } from "../Loading";
import moment from "moment-timezone";

moment.tz.setDefault("America/Sao_Paulo");
const nextMonth = moment().add(1, 'month').format('DD/MM/YYYY');

interface IPaymentFormProps {
    area?: number;
    price: number;
    type: string;
    level: string;
    itens: [{
        title: string;
        price: number;
    }]
}

interface ICreditCardInfo {
    holderName: string,
    number: string,
    expirationDate: string,
    cvv: string
}

interface ICreditCardHolderInfo {
    name: string,
    email: string,
    cpfCnpj: string,
    postalCode: string,
    addressNumber: string,
    addressComplement: string,
    address: string,
    province: string,
    phone: string
}

interface IAccountInfo {
    name: string,
    email: string,
    cpfCnpj: string,
    password: string,
    confirmPassword: string
}

const creditCardInfoInitialState = {
    holderName: "",
    number: "",
    expirationDate: "",
    cvv: ""
}

const creditCardHolderInfoInitialState = {
    name: "",
    email: "",
    cpfCnpj: "",
    postalCode: "",
    addressNumber: "",
    addressComplement: " ",
    address: "",
    province: " ",
    phone: ""
}

const accountInfoInitialState = {
    name: "",
    email: "",
    cpfCnpj: "",
    password: "",
    confirmPassword: ""
}

export const PaymentForm = (props: IPaymentFormProps) => {
    const { t } = useTranslation();

    const { price, itens, type, level, area } = props;
    const [creditCardInfo, setCreditCardInfo] = useState(creditCardInfoInitialState as ICreditCardInfo);
    const [creditCardHolderInfo, setCreditCardHolderInfo] = useState(creditCardHolderInfoInitialState as ICreditCardHolderInfo);
    const [accountInfo, setAccountInfo] = useState(accountInfoInitialState as IAccountInfo);
    const [pageState, setPageState] = useState<any>({ status: 'pending' });
    const [errors, setErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [logged, setLogged] = useState(false);
    const [userCanHasFreeTrial, setUserCanHasFreeTrial] = useState(!logged);
    const { signup, login } = useAuth();

    const navigate = useNavigate();

    const { isLogged } = useAuth();

    useEffect(() => {
        async function checkLogged() {
            setLogged(await isLogged());
        }

        if (pageState.status === 'pending') {
            checkLogged();
        }
    });

    useEffect(() => {
        if (logged) {
            const token = localStorage.getItem("@BiosatToken");

            const headers = {
                authorization: token || ""
            }

            const body = {
                type: type,
            }

            api.post("/plan/checkTrial", body, { headers }).then(response => {
                setUserCanHasFreeTrial(response.data.result);
            });
        } else {
            setUserCanHasFreeTrial(true);
        }
    }, [logged]);

    const validateFields = () => {
        const cardNumber = validateCreditCard(creditCardInfo.number.replace(/\s/g, ""));
        const name = validateName(creditCardInfo.holderName);
        const expirationDate = validadeExpirationDate(creditCardInfo.expirationDate.replace("/", ""));
        const email = validateEmail(creditCardHolderInfo.email);
        const cpfCnpj = validateCpfCnpj(creditCardHolderInfo.cpfCnpj);
        const postalCode = validatePostalCode(creditCardHolderInfo.postalCode);
        const phone = validatePhone(creditCardHolderInfo.phone);
        const addressNumber = creditCardHolderInfo.addressNumber !== "";
        const address = creditCardHolderInfo.address !== "" || creditCardHolderInfo.address.length < 3;

        if (!cardNumber) {
            setErrors({ ...errors, cardNumber: t('invalidCard') });
        }

        if (!name) {
            setErrors({ ...errors, holderName: t('invalidName') });
        }

        if (!expirationDate) {
            setErrors({ ...errors, expirationDate: t('invalidExpiration') });
        }

        if (!email) {
            setErrors({ ...errors, email: "Email inválido" });
        }

        if (!cpfCnpj) {
            setErrors({ ...errors, cpfCnpj: "CPF/CNPJ inválido" });
        }

        if (!postalCode) {
            setErrors({ ...errors, postalCode: "CEP inválido" });
        }

        if (!phone) {
            setErrors({ ...errors, phone: "Telefone inválido" });
        }

        if (!addressNumber) {
            setErrors({ ...errors, addressNumber: "Número inválido" });
        }

        if (!address) {
            setErrors({ ...errors, address: "Endereço inválido" });
        }

        // se o usuario estiver logado, não precisa validar os campos de conta
        function validateAccountInfo() {
            const nameValidation = validateName(accountInfo.name);
            const emailValidation = validateEmail(accountInfo.email);
            const cpfCnpjValidation = validateCpfCnpj(accountInfo.cpfCnpj);

            if (!logged) {
                if (accountInfo.password === "") {
                    setErrors({ ...errors, password: "Senha não pode ser vazia" });
                }

                if (accountInfo.confirmPassword === "") {
                    setErrors({ ...errors, confirmPassword: "Senha não pode ser vazia" });
                }

                if (accountInfo.password !== accountInfo.confirmPassword) {
                    setErrors({ ...errors, confirmPassword: "Senhas não conferem" });
                }

                if (!nameValidation) {
                    setErrors({ ...errors, accountName: t('invalidName') });
                }

                if (!emailValidation) {
                    setErrors({ ...errors, accountEmail: "Email inválido" });
                }

                if (!cpfCnpjValidation) {
                    setErrors({ ...errors, accountCpfCnpj: "CPF/CNPJ inválido" });
                }

                return accountInfo.password !== "" &&
                    accountInfo.confirmPassword !== "" &&
                    accountInfo.password === accountInfo.confirmPassword;
            }

            return true;
        }

        return cardNumber &&
            name &&
            expirationDate &&
            email &&
            cpfCnpj &&
            postalCode &&
            phone &&
            creditCardHolderInfo.addressNumber !== "" &&
            creditCardHolderInfo.address !== "" &&
            validateAccountInfo();
    }

    const handlePayment = async () => {
        setPageState({ status: "loading" });

        const validationResult = validateFields();

        if (!validationResult) {
            setPageState({ status: "pending" });

            return;
        }

        let token;

        if (!logged) {
            setLoadingMessage("Criando sua conta...");

            try {
                await signup(
                    accountInfo.name,
                    accountInfo.email,
                    accountInfo.cpfCnpj,
                    accountInfo.password,
                    accountInfo.confirmPassword
                );

                setLoadingMessage("Fazendo login...");

                token = await login(accountInfo.email, accountInfo.password);

            } catch (error: any) {
                setPageState({ status: "pending" });

                toast.error(error.message);

                return;
            }
        }

        if (!token) {
            token = localStorage.getItem("@BiosatToken") || "";
        }

        const planLevel: number = Number(level);

        const body = {
            area: area,
            price: price.toString(),
            planLevel,
            type: type,
            creditCardInfo: {
                holderName: creditCardInfo.holderName,
                number: creditCardInfo.number,
                expirationMonth: creditCardInfo.expirationDate.split("/")[0],
                expirationYear: creditCardInfo.expirationDate.split("/")[1],
                cvv: creditCardInfo.cvv
            },
            creditCardHolderInfo: creditCardHolderInfo
        }

        setIsLoading(true);

        function getGtagEvent() {
            const eventName = type === 'PLATFORM' ? 'Pagamento_Plataforma' : 'Pagamento_API';

            const params = {
                value: price,
                level: '',
            }

            if (planLevel === 1) {
                params['level'] = 'Básico';
            } else if (planLevel === 2) {
                params['level'] = 'Intermediário';
            } else if (planLevel === 3) {
                params['level'] = 'Avançado';
            }

            return {
                eventName,
                params
            }
        }

        setLoadingMessage("Efetuando pagamento");
        api.post("/plan", body, { headers: { authorization: token } }).then(res => {
            setIsLoading(false);
            setPageState({ status: "success", msg: res.data.msg });

            const gtagEvent = getGtagEvent();

            ReactGA.gtag('event', gtagEvent.eventName, gtagEvent.params);

            if (res.data.showPlatformAlert === true) {
                toast.warn(t('checkEmailMessage'));
            }

            navigate("/payment/success");
        }).catch(err => {
            setIsLoading(false);
            setPageState({ status: "error", msg: err.response.data.msg });
        });
    }

    return (
        <>
            <ToastContainer />
            <div style={{ background: "#0A1E29", minHeight: "100vh" }}>

                <Navbar positionRelative={true} bgTransparent={true} />

                {
                    pageState.status === 'pending' && (
                        <Form>
                            <div>
                                <Form.Wrapper>
                                    <Form.Card>
                                        <Form.Title>
                                            {t('paymentTitle')}
                                        </Form.Title>

                                        {
                                            logged === false && (
                                                <>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Dados de Cadastro
                                                        </Form.Label>
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Form.Control>
                                                            <Form.Label>
                                                                Nome Completo
                                                            </Form.Label>

                                                            <Form.Input
                                                                value={accountInfo.name}
                                                                onChange={(e) => setAccountInfo({ ...accountInfo, name: e.target.value })}
                                                                error={errors.accountName}
                                                                onBlur={() => {
                                                                    const nameValidation = validateName(accountInfo.name);

                                                                    if (!nameValidation) {
                                                                        setErrors({ ...errors, accountName: t('invalidName') });
                                                                    } else {
                                                                        setErrors({ ...errors, accountName: "" });
                                                                    }
                                                                }}
                                                            />
                                                        </Form.Control>
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Form.Control>
                                                            <Form.Label>
                                                                Email
                                                            </Form.Label>

                                                            <Form.Input
                                                                value={accountInfo.email}
                                                                onChange={(e) => setAccountInfo({ ...accountInfo, email: e.target.value })}
                                                                error={errors.accountEmail}
                                                                onBlur={() => {
                                                                    const emailValidation = validateEmail(accountInfo.email);

                                                                    if (!emailValidation) {
                                                                        setErrors({ ...errors, accountEmail: "Email inválido" });
                                                                    } else {
                                                                        setErrors({ ...errors, accountEmail: "" });
                                                                    }
                                                                }}
                                                            />
                                                        </Form.Control>

                                                        <Form.Control>
                                                            <Form.Label>
                                                                CPF/CNPJ
                                                            </Form.Label>

                                                            <Form.Input
                                                                value={getMaskForCpfCnpj(accountInfo.cpfCnpj)}
                                                                maxLength={18}
                                                                accept={"[0-9]"}
                                                                onChange={(e: any) => {
                                                                    const value = e.target.value.replace(/\D/g, "");

                                                                    setAccountInfo({ ...accountInfo, cpfCnpj: value });
                                                                }}
                                                                onBlur={e => {
                                                                    const value = e.target.value.replace(/\D/g, "");

                                                                    const result = validateCpfCnpj(value);

                                                                    if (!result) {
                                                                        setErrors({ ...errors, accountCpfCnpj: "CPF/CNPJ inválido" });
                                                                    } else if (errors.accountCpfCnpj) {
                                                                        setErrors({ ...errors, accountCpfCnpj: "" });
                                                                    }
                                                                }}
                                                                error={errors.accountCpfCnpj}
                                                            />
                                                        </Form.Control>
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Form.Control>
                                                            <Form.Label>
                                                                Senha
                                                            </Form.Label>

                                                            <Form.Input
                                                                value={accountInfo.password}
                                                                onChange={(e) => setAccountInfo({ ...accountInfo, password: e.target.value })}
                                                                error={errors.accountPassword}
                                                                type="password"
                                                                onBlur={() => {
                                                                    if (accountInfo.password === "") {
                                                                        setErrors({ ...errors, accountPassword: "Campo obrigatório" });
                                                                    } else if (errors.accountPassword) {
                                                                        setErrors({ ...errors, accountPassword: "" });
                                                                    }
                                                                }}
                                                            />
                                                        </Form.Control>

                                                        <Form.Control>
                                                            <Form.Label>
                                                                Confirmar Senha
                                                            </Form.Label>

                                                            <Form.Input
                                                                value={accountInfo.confirmPassword}
                                                                onChange={(e) => setAccountInfo({ ...accountInfo, confirmPassword: e.target.value })}
                                                                error={errors.accountConfirmPassword}
                                                                type="password"
                                                                onBlur={() => {
                                                                    if (accountInfo.password !== accountInfo.confirmPassword) {
                                                                        setErrors({ ...errors, accountConfirmPassword: "As senhas não coincidem" });
                                                                    } else if (errors.accountConfirmPassword) {
                                                                        setErrors({ ...errors, accountConfirmPassword: "" });
                                                                    }
                                                                }}
                                                            />
                                                        </Form.Control>
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Form.Anchor
                                                            onClick={() => { setIsModalOpen(true) }}
                                                        >
                                                            Já tenho conta
                                                        </Form.Anchor>
                                                    </Form.Group>

                                                    <LoginModal isOpen={isModalOpen} onClose={() => { setIsModalOpen(false) }} />
                                                </>
                                            )

                                        }

                                        <Form.Group>
                                            <Form.Label>
                                                {t('cardLabel')}:
                                            </Form.Label>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Control>
                                                <Form.Label>
                                                    {t('cardNumber')} <span>{errors.cardNumber && " - " + errors.cardNumber}</span>
                                                </Form.Label>

                                                <Form.Input
                                                    value={getCardMask(creditCardInfo.number)}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/\D/g, "");

                                                        setCreditCardInfo({ ...creditCardInfo, number: value })
                                                    }}
                                                    maxLength={19}
                                                    onBlur={e => {
                                                        const value = e.target.value.replace(/[- ]/g, "");

                                                        const result = validateCreditCard(value);

                                                        if (!result) {
                                                            setErrors({ ...errors, cardNumber: t('invalidCard') });
                                                        } else if (errors.cardNumber) {
                                                            setErrors({ ...errors, cardNumber: "" });
                                                        }
                                                    }}
                                                    error={errors.cardNumber}
                                                    accept={"[0-9]"}
                                                />
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Control>
                                                <Form.Label>
                                                    {t('cardOwner')} <span>{errors.name && " - " + errors.name}</span>
                                                </Form.Label>

                                                <Form.Input
                                                    value={creditCardInfo.holderName}
                                                    onChange={(e) => setCreditCardInfo({ ...creditCardInfo, holderName: e.currentTarget.value })}
                                                    onBlur={e => {
                                                        const result = validateName(e.target.value);

                                                        if (!result) {
                                                            setErrors({ ...errors, name: t('incompleteName') });
                                                        } else if (errors.name) {
                                                            setErrors({ ...errors, name: "" });
                                                        }
                                                    }}
                                                    error={errors.name}
                                                />
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group style={{ gridTemplateColumns: "2fr 1fr" }}>
                                            <Form.Control>
                                                <Form.Label>
                                                    {t('cardExpiration')} <span>{errors.expirationDate && " - " + errors.expirationDate}</span>
                                                </Form.Label>

                                                <Form.Input
                                                    value={getExpirationDateMask(creditCardInfo.expirationDate)}
                                                    onChange={(e) => setCreditCardInfo({ ...creditCardInfo, expirationDate: e.currentTarget.value })}
                                                    maxLength={5}
                                                    onBlur={e => {
                                                        const value = e.target.value.replace(/[/ ]/g, "");

                                                        const result = validadeExpirationDate(value);

                                                        if (!result) {
                                                            setErrors({ ...errors, expirationDate: t('invalidExpiration') });
                                                        } else if (errors.expirationDate) {
                                                            setErrors({ ...errors, expirationDate: "" });
                                                        }
                                                    }}
                                                    error={errors.expirationDate}
                                                />
                                            </Form.Control>

                                            <Form.Control>
                                                <Form.Label>
                                                    {t('cardCVC')} <span>{errors.cvv && " - " + errors.cvv}</span>
                                                </Form.Label>

                                                <Form.Input
                                                    value={creditCardInfo.cvv}
                                                    onChange={(e) => setCreditCardInfo({ ...creditCardInfo, cvv: e.currentTarget.value })}
                                                    maxLength={3}
                                                    accept={"[0-9]"}
                                                    onBlur={e => {
                                                        if (e.target.value.length < 3) {
                                                            setErrors({ ...errors, cvv: t('invalidCVC') });
                                                        } else if (errors.cvv) {
                                                            setErrors({ ...errors, cvv: "" });
                                                        }
                                                    }}
                                                    error={errors.cvv}
                                                />
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>
                                                {t('cardOwnerLabel')}:
                                            </Form.Label>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Control>
                                                <Form.Label>
                                                    {t('cardOwnerName')} <span>{errors.holderName && " - " + errors.holderName}</span>
                                                </Form.Label>

                                                <Form.Input
                                                    value={creditCardHolderInfo.name}
                                                    onChange={(e) => setCreditCardHolderInfo({ ...creditCardHolderInfo, name: e.currentTarget.value })}
                                                    onBlur={e => {
                                                        const result = validateName(e.target.value);

                                                        if (!result) {
                                                            setErrors({ ...errors, holderName: t('incompleteName') });
                                                        } else if (errors.holderName) {
                                                            setErrors({ ...errors, holderName: "" });
                                                        }
                                                    }}
                                                    error={errors.holderName}
                                                />
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group style={{ gridTemplateColumns: "1.5fr 1fr" }}>
                                            <Form.Control>
                                                <Form.Label>
                                                    Email
                                                </Form.Label>

                                                <Form.Input
                                                    value={creditCardHolderInfo.email}
                                                    onChange={(e) => setCreditCardHolderInfo({ ...creditCardHolderInfo, email: e.currentTarget.value })}
                                                    onBlur={e => {
                                                        const result = validateEmail(e.target.value);

                                                        if (!result) {
                                                            setErrors({ ...errors, email: "Email inválido" });
                                                        } else if (errors.email) {
                                                            setErrors({ ...errors, email: "" });
                                                        }
                                                    }}
                                                    error={errors.email}
                                                />
                                            </Form.Control>

                                            <Form.Control>
                                                <Form.Label>
                                                    {t('cardOwnerPhone')}
                                                </Form.Label>

                                                <Form.Input
                                                    value={getPhoneMask(creditCardHolderInfo.phone)}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/\D/g, "");

                                                        setCreditCardHolderInfo({ ...creditCardHolderInfo, phone: value })
                                                    }}
                                                    maxLength={15}
                                                    accept={"[0-9]"}
                                                    onBlur={e => {
                                                        const value = e.target.value.replace(/[- ()]/g, "");

                                                        const result = validatePhone(value);

                                                        if (!result) {
                                                            setErrors({ ...errors, phone: "Telefone inválido" });
                                                        } else if (errors.phone) {
                                                            setErrors({ ...errors, phone: "" });
                                                        }
                                                    }}
                                                    error={errors.phone}
                                                />
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Control>
                                                <Form.Label>
                                                    CPF
                                                </Form.Label>

                                                <Form.Input
                                                    value={getMaskForCpfCnpj(creditCardHolderInfo.cpfCnpj)}
                                                    maxLength={18}
                                                    accept={"[0-9]"}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/\D/g, "");

                                                        setCreditCardHolderInfo({ ...creditCardHolderInfo, cpfCnpj: value });
                                                    }}
                                                    onBlur={e => {
                                                        const value = e.target.value.replace(/\D/g, "");

                                                        const result = validateCpfCnpj(value);

                                                        if (!result) {
                                                            setErrors({ ...errors, cpfCnpj: "CPF ou CNPJ inválido" });
                                                        } else if (errors.cpfCnpj) {
                                                            setErrors({ ...errors, cpfCnpj: "" });
                                                        }
                                                    }}
                                                    error={errors.cpfCnpj}
                                                />
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Control>
                                                <Form.Label>
                                                    {t('cardOwnerAddress')}
                                                </Form.Label>

                                                <Form.Input
                                                    value={creditCardHolderInfo.address}
                                                    onChange={(e) => setCreditCardHolderInfo({ ...creditCardHolderInfo, address: e.currentTarget.value })}
                                                    onBlur={e => {
                                                        if (!e.target.value || e.target.value.length < 3) {
                                                            setErrors({ ...errors, address: "Logradouro inválido" });
                                                        } else if (errors.address) {
                                                            setErrors({ ...errors, address: "" });
                                                        }
                                                    }}
                                                    error={errors.address}
                                                />
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group style={{ gridTemplateColumns: "2fr 1fr" }}>
                                            <Form.Control>
                                                <Form.Label>
                                                    CEP
                                                </Form.Label>

                                                <Form.Input
                                                    value={getPostalCodeMask(creditCardHolderInfo.postalCode)}
                                                    onChange={(e) => {
                                                        setCreditCardHolderInfo({ ...creditCardHolderInfo, postalCode: e.currentTarget.value })
                                                    }}
                                                    accept={"[0-9]"}
                                                    maxLength={9}
                                                    onBlur={e => {
                                                        const value = e.target.value.replace(/\D/g, "");

                                                        const result = validatePostalCode(value);

                                                        if (!result) {
                                                            setErrors({ ...errors, postalCode: "CEP inválido" });
                                                        } else if (errors.postalCode) {
                                                            setErrors({ ...errors, postalCode: "" });
                                                        }
                                                    }}
                                                    error={errors.postalCode}
                                                />
                                            </Form.Control>

                                            <Form.Control>
                                                <Form.Label>
                                                    {t('cardOwnerNumber')}
                                                </Form.Label>

                                                <Form.Input
                                                    value={creditCardHolderInfo.addressNumber}
                                                    onChange={(e) => setCreditCardHolderInfo({ ...creditCardHolderInfo, addressNumber: e.currentTarget.value })}
                                                    onBlur={e => {
                                                        if (!e.target.value || e.target.value.length < 1) {
                                                            setErrors({ ...errors, addressNumber: "Número inválido" });
                                                        } else if (errors.addressNumber) {
                                                            setErrors({ ...errors, addressNumber: "" });
                                                        }
                                                    }}
                                                    error={errors.addressNumber}
                                                />
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Control>
                                                <Form.Label>
                                                    {t('cardOwnerComplement')}
                                                </Form.Label>

                                                <Form.Input
                                                    value={creditCardHolderInfo.addressComplement}
                                                    onChange={(e) => setCreditCardHolderInfo({ ...creditCardHolderInfo, addressComplement: e.currentTarget.value })}
                                                />
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Button
                                            onClick={handlePayment}
                                            disabled={isLoading}
                                        >
                                            {
                                                isLoading ? (
                                                    t('paymentProcessing')
                                                ) : (
                                                    t('paymentButton')
                                                )
                                            }
                                        </Form.Button>

                                    </Form.Card>

                                    <Form.Divider />

                                    <Form.Card>
                                        <Form.Title>
                                            {t('order')}
                                        </Form.Title>

                                        {
                                            itens.map((item, index) => (
                                                <CheckoutPrice
                                                    key={index}
                                                    title={item.title}
                                                    text={item.title === "Hectares" ? `${area} ha` : `${item.price.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`}
                                                />
                                            ))
                                        }

                                        <Form.Divider style={{ width: "100%", height: "1px" }} />

                                        <CheckoutPrice text={`R$ ${price.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`} title={"Subtotal"} />

                                        {
                                            userCanHasFreeTrial && (
                                                <CheckoutPrice
                                                    text={`Grátis até ${nextMonth}`}
                                                    title={"Próximo pagamento"}
                                                    color="#F27E30"
                                                />
                                            )
                                        }

                                        <CheckoutPrice
                                            text={`R$ ${price.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`}
                                            title={"Total"}
                                            isTotal
                                        />
                                    </Form.Card>
                                </Form.Wrapper>
                            </div>
                        </Form>
                    )
                }

                {
                    pageState.status === "success" && (
                        <Success
                            msg={pageState.msg}
                            t={t}
                        />
                    )
                }

                {
                    pageState.status === "error" && (
                        <Error
                            msg={pageState.msg}
                            t={t}
                            backToForm={() => setPageState({ status: "pending" })}
                        />
                    )
                }

                {
                    pageState.status === "loading" && (
                        <LoadingCard
                            message={loadingMessage}
                        />
                    )
                }
            </div>
        </>
    )
}

const Success = (props: { msg: string, t: TFunction }) => {
    const { msg, t } = props

    const navigate = useNavigate()

    return (
        <Form>
            <Form.Card>
                <Form.Result>
                    <Form.ResultTitle>
                        {t('successPaymentTitle')}
                    </Form.ResultTitle>

                    <Form.ResultImageContainer>
                        <img src={SuccessImage} alt="Sucesso" />
                    </Form.ResultImageContainer>

                    <Form.ResultText>
                        {msg || t('successPaymentMessage')}
                    </Form.ResultText>
                </Form.Result>

                <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "2rem 0" }}>
                    <Form.Button
                        onClick={() => navigate("/dashboard?tab=plans")}
                    >
                        {t('successPaymentButton')}
                    </Form.Button>
                </div>
            </Form.Card>
        </Form>
    )
}

const Error = (props: { msg: any, t: TFunction, backToForm: () => void }) => {
    const { msg, t, backToForm } = props;

    return (
        <Form>
            <Form.Card>
                <Form.Result>
                    <Form.ResultTitle>
                        {t('errorPaymentTitle')}
                    </Form.ResultTitle>

                    <Form.ResultImageContainer>
                        <img src={ErrorImage} alt="Erro" />
                    </Form.ResultImageContainer>

                    <Form.ResultText>
                        {
                            msg || t('errorPaymentMessage')
                        }
                    </Form.ResultText>
                </Form.Result>

                <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "2rem 0", flexDirection: "column" }}>
                    <Form.SecondaryButton
                        style={{ border: "2px solid #F27E30" }}
                        onClick={() => { backToForm() }}
                    >
                        {t('errorPaymentButton')}
                    </Form.SecondaryButton>
                </div>
            </Form.Card>
        </Form>
    )
}

interface LoginModalProps {
    isOpen: boolean;
    onClose: () => void;
}

function LoginModal(props: LoginModalProps) {
    const { isOpen, onClose } = props;
    const { login } = useAuth();
    const { t } = useTranslation();

    const [errors, setErrors] = useState({ email: "", password: "" });
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    function handleLogin() {
        setIsLoading(true);
        login(email, password)
            .then(() => {
                onClose();
            })
            .catch((err) => {
                setIsLoading(false);

                if (err.type === 'form') {
                    setErrors(err.errors);
                } else if (err.type === 'request') {
                    toast.error(err.message);
                    setErrors({ email: "", password: "" });
                }
            });
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={"Login"}
        >
            <ToastContainer />

            <Form.Group>
                <Form.Control>
                    <Form.Label
                        style={{ color: "#FFF" }}
                    >
                        Email
                    </Form.Label>

                    <Form.Input
                        value={email}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                        error={errors.email !== ""}
                    />
                </Form.Control>

                <Form.Control>
                    <Form.Label
                        style={{ color: "#FFF" }}
                    >
                        Senha
                    </Form.Label>

                    <Form.Input
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        type="password"
                        error={errors.password !== ""}
                    />
                </Form.Control>
            </Form.Group>

            <Form.Button
                onClick={handleLogin}
            >
                {
                    isLoading ? (
                        <div style={{ height: "35px", width: "35px", margin: '0 auto' }}>
                            <Loading />
                        </div>
                    ) : (
                        t('authButton')
                    )
                }
            </Form.Button>
        </Modal>
    )
}

function LoadingCard({ message }: { message?: string }) {
    return (
        <Form>
            <Form.Card>
                <Form.Result>
                    <Form.ResultTitle>
                        Carregando
                    </Form.ResultTitle>

                    <Form.ResultImageContainer>
                        <div style={{ height: "60px", width: "60px", margin: '0 auto' }}>
                            <Loading />
                        </div>
                    </Form.ResultImageContainer>

                    <Form.ResultText>
                        {message || "Aguarde um momento..."}
                    </Form.ResultText>
                </Form.Result>
            </Form.Card>
        </Form>
    )
}