import styled from "styled-components";

const Main = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: 0 auto;
    width: 100%;
    height: fit-content;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 5px;

    @media (max-width: 999px) {
        max-width: 630px;
    }
`;

const Title = styled.h1`
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #07130A;
    width: 100%;
    font-family: Archivo, sans-serif;
`;

const ImageContainer = styled.figure`
    width: 100%;
    height: 300px;
    margin-bottom: 1rem;
    border-radius: 5px;
    overflow: hidden;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const ButtonFlex = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;
`;

const Button = styled.button`
    background: #62C77A;
    border: none;
    border-radius: 5px;
    padding: 10px;
    min-width: 150px;
    color: #FFF;
    font-weight: 700;
    margin-top: 10px;
`;

const Subtitle = styled.p`
    font-size: 18px;
    font-weight: 400;
    color: #07130A;
    width: 100%;
    font-family: Inter, sans-serif;
    letter-spacing: 0.5px;
    line-height: 1.5rem;
`;


export const PostCard = Object.assign(Main, {
    Title,
    ImageContainer,
    Image,
    Content,
    ButtonFlex,
    Button,
    Subtitle
});