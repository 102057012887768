import styled from "styled-components";

export const Footer = styled.footer`
    background: #07131B;
    padding: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    position: relative;
    color: #fff;
    margin-top: 2rem;
    line-height: 20px;
  
    @media (max-width: 800px) {
        padding: 1rem;
    }
`;

export const FooterLogo = styled.div`
    width: 300px;
    padding: 20px;

    img {
        width: 100%;
    }
  
    @media (max-width: 800px) {
        width: 280px;
        margin-top: 4rem;
    }
`;

export const FooterLinks = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 0 2rem;
    font-family: Archivo, serif;
    font-weight: 500;

    h3 {
        font-size: 25px;
        line-height: 40px;
        color: #FFF;
        padding: 20px 0;
    }

    p {
        font-size: 14px;
        line-height: 20px;
        color: #FFF;
    }

    img {
      margin-right: 10px;
    }

    div {
      margin-top: 1rem;
    }

    a {
        color: #FFF;
        text-decoration: none;
        margin-bottom: 1rem;
    }
  
    @media (max-width: 800px) {
      padding: 0;
      
      h3 {
        font-size: 24px;
        line-height: 25px;
      }
      
        p {
            font-size: 12px;
            line-height: 16px;
        }
    }
`;

export const Link = styled.a`
    display: grid;
    place-items: center;
    grid-auto-flow: column;
    gap: .5rem;

    &:hover {
        p, svg {
            opacity: .8;
        }
    }

    @media (max-width: 800px) {
        margin-left: 1rem;
    }
`;

export const Stack = styled.div<{
    justifyContent?: string;
    alignItems?: string;
    direction?: string;
    other?: string;
}>`
    display: flex;
    flex-direction: ${props => props.direction || "column"};
    justify-content: ${props => props.justifyContent || "center"};
    align-items: ${props => props.alignItems || "center"};
    width: 100%;
    ${props => props.other || ""}
`;