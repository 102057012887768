import { ModalContainer } from "./styled";
import { AiOutlineCloseCircle } from "react-icons/ai";

interface ModalProps {
    title: string;
    children: React.ReactNode;
    isOpen: boolean;
    bgColor?: string;
    onClose: () => void;
}

export const Modal = (props: ModalProps) => {

    if (!props.isOpen) {
        document.body.style.overflow = "auto";

        return null;
    } else if (props.isOpen) {
        document.body.style.overflow = "hidden";
    }

    return (
        <ModalContainer
            onClick={props.onClose}
        >
            <ModalContainer.Content
                onClick={(e) => e.stopPropagation()}
                style={{ backgroundColor: props.bgColor }}
            >
                <ModalContainer.Header>
                    <ModalContainer.Title>
                        {props.title}
                    </ModalContainer.Title>

                    <ModalContainer.CloseButton
                        onClick={props.onClose}
                    >
                        <AiOutlineCloseCircle />
                    </ModalContainer.CloseButton>
                </ModalContainer.Header>

                <ModalContainer.Divider />
                {props.children}
            </ModalContainer.Content>
        </ModalContainer>
    )
}